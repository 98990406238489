import React from 'react';
import './App.css';

function About() {
  return (
  <>
  <h1>Welcome About Page</h1>
  </>
  );
}

export default About;
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
// import '../../App.css';
import './dashboard.css';
import Swal from 'sweetalert2'

import DashboardNav from './dashboardNav';
import LoginServices from '../../services/LoginServices';
import Login from '../Login';



function DServices() {
    const navigate = useNavigate();
    const [userDetails, setUserDetails] = useState<any>();

    const userId = localStorage.getItem('userid');
    useEffect(() => {
        if (userId) {

            let config = {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            };
            LoginServices.getUserDetailsByID(parseInt(userId), config)
                .then((response: any) => {
                    console.log(response);
                    if (response.data.Status == 1) {
                        setUserDetails(response?.data?.UserDetails);
                        localStorage.setItem('isKycDone', response?.data?.UserDetails?.isKYCverified)
                    }
                    else {
                    }
                    console.log(response.data);
                })
                .catch((e: Error) => {
                    console.log(e);
                });
        } else {
            alert('Login to see your dashboard')
            navigate('/')
        }

    }, ['']);

    const comingSoon = () => {
        Swal.fire({
            title: 'Coming Soon',
            icon: 'info',
            confirmButtonText: 'Ok'
        })
    }

    return (
        <>
            <React.Fragment>

                <div className="main-bg bbm">
                    <div className="bb0">
                        <DashboardNav active={"services"}></DashboardNav>
                    </div>

                    <div className="bb1" >
                        <div style={{ padding: '30px 45px' }}>
                            <div className='d-flex-j-sp'>
                                <div>
                                    <h1 className="head-dashboard">Services</h1>
                                </div>
                            </div>
                            <div className="row service-box-23-row">
                                <div className="col-sm">
                                    <div className="service-box-23">
                                        <Link to="/dashboard/pay-bills">
                                            <img className="service-box-icon" src='assets/services/housing.png' alt="" />
                                            Housing Society
                                        </Link>
                                    </div>
                                    <div onClick={comingSoon} className="service-box-23">
                                        <img className="service-box-icon" src='assets/services/credit-card.png' alt="" />
                                        Credit Card Payment
                                    </div>
                                    <div onClick={comingSoon} className="service-box-23">
                                        <img className="service-box-icon" src='assets/services/education.png' alt="" />
                                        Education Fees
                                    </div>
                                    <div onClick={comingSoon} className="service-box-23">
                                        <img className="service-box-icon" src='assets/services/gas.png' alt="" />
                                        LPG Gas
                                    </div>
                                </div>
                                <div className="col-sm">
                                    <div onClick={comingSoon} className="service-box-23">
                                        <img className="service-box-icon" src='assets/services/tax.png' alt="" />
                                        Muncipal Taxes
                                    </div>
                                    <div onClick={comingSoon} className="service-box-23">
                                        <img className="service-box-icon" src='assets/services/toll.png' alt="" />
                                        FasTag Recharge
                                    </div>
                                    <div className="service-box-23">
                                        <Link to="/dashboard/pay-bills">
                                            <img className="service-box-icon" src='assets/services/payments.png' alt="" />
                                            Other Recurring Payments
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        </>
    );
}

export default DServices;

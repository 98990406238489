import React, { useState, useEffect } from "react";
import Moment from "react-moment";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import DashboardNav from "./dashboardNav";
import "./dashboard.css";
import WalletBlcHeader from "./walletBlcHeader";
import LoginServices from "../../services/LoginServices";
import Razor from "./Razor";
import { Modal, Row, Col } from "react-bootstrap";
import { css } from "@emotion/react";

import { PulseLoader } from "react-spinners";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const override = css`
    display: block;
    margin: 0 auto;
    border-color: "#010080";
`;

function PayWithoutSignin() {
    let { recId } = useParams();
    let [loading, setLoading] = useState(false);
    const userId = localStorage.getItem("userid");
    const navigate = useNavigate();
    const {
        register,
        formState: { errors },
        handleSubmit,
        reset,
    } = useForm();

    const [rEmail, setREmail] = useState<any>("");
    const [rMobile, setRMobile] = useState<any>("");

    const [tempDetails, setTempDetails] = useState<any>({});

    const [userIpAddress, setUserIpAddress] = useState("");
    useEffect(() => {
        fetch("https://api.ipify.org/?format=json")
            .then((Response) => {
                return Response.json();
            })
            .then((response) => {
                console.log(response);
                setUserIpAddress(response.ip);
            })
            .catch((err) => {
                console.log(err);
            });

        if (recId?.length == 0) {
            alert("PaymentPageId is not available");
            navigate("/");
            return;
        }
        const script = document.createElement("script");
        script.src = "https://checkout.razorpay.com/v1/checkout.js";
        script.async = true;
        document.body.appendChild(script);
    }, [""]);

    const onSubmit = (data: any) => {
        console.log(data);
        setREmail(data.emailId);
        setRMobile(data.mobileNumber);

        const payload = {
            amount: data.amount,
            paymentPageId: recId,
            name: data.name,
            mobileNumber: data.mobileNumber,
            emailId: data.emailId,
            notes: data.notes,
            ipAddress: userIpAddress,
        };

        setLoading(true);
        LoginServices.sendPaymentPageID(payload)
            .then((response: any) => {
                console.log(response);
                if (response.data.Status == 1) {
                    localStorage.setItem("receiptId", response.data.receiptId);
                    setLoading(false);
                    // options.amount = 500 * 100;
                    options.order_id = response.data.orderId;
                    options.prefill = {
                        contact: "+91" + rMobile,
                        email: rEmail,
                    };

                    console.log(options);

                    var rzp1 = new (window as any).Razorpay(options);
                    rzp1.open();
                } else {
                    setLoading(false);
                    alert(response.data.Message);
                }
                console.log(response.data);
            })
            .catch((e: Error) => {
                setLoading(false);
                console.log(e);
            });
    };
    console.log(tempDetails);

    const options: any = {
        key: process.env.REACT_APP_RAZOR_PAY_KEY,
        client_id: process.env.REACT_APP_RAZOR_PAY_CLIENT_ID,
        client_secret: process.env.REACT_APP_RAZOR_PAY_CLIENT_SECRET,
        grant_type: process.env.REACT_APP_RAZOR_PAY_GRANT_TYPE,
        mode: "test",
        amount: 0, //  = INR 1
        name: "PayMark",
        description: "Pay",
        order_id: "",
        handler: function (response: any) {
            console.log(response);
            const payload = {
                receiptId: localStorage.getItem("receiptId"),
                description: "",
                paymentPageId: recId,
                razorpay_payment_id: response.razorpay_payment_id,
                razorpay_signature: response.razorpay_signature,
                // amount: 500
            };
            setLoading(true);
            LoginServices.createRazorpayPaymentGlobal(payload)
                .then((item: any) => {
                    console.log(item);
                    if (item.data.Status == 1) {
                        setLoading(false);
                        alert(item.data.Message);
                        window.location.reload();
                    } else {
                        setLoading(false);
                        alert(item?.data?.Message);
                    }
                })
                .catch((e: Error) => {
                    setLoading(false);
                    console.log(e);
                });
            // alert(response.razorpay_payment_id);
        },
        // prefill: {
        //     name: 'Gaurav',
        //     contact: '9999999999',
        //     email: 'demo@demo.com'
        // },
        notes: {
            address: "some address",
        },
        theme: {
            color: "#6865F0",
            hide_topbar: false,
        },
    };

    return (
        <>
            <div className="main-bg">
                <div>
                    <img
                        className="pay-mark-logo"
                        src="assets/img/logo.png"
                        alt=""
                    />
                </div>
                <div>
                    <p className="kyc-head">Make Payment </p>

                    <div>
                        <div
                            className="row body"
                            style={{
                                alignItems: "center",
                                justifyContent: "center",
                                marginTop: "40px",
                            }}>
                            <div className="kyc-main-div">
                                <form
                                    className="form-sub"
                                    onSubmit={handleSubmit(onSubmit)}>
                                    <div>
                                        {/* <p className="titel"></p> */}
                                        <div className="ip-inr-main mar-t-b-5">
                                            <label
                                                style={{ width: "260px" }}
                                                className="label ">
                                                Name
                                            </label>
                                            <input
                                                {...register("name", {
                                                    required: true,
                                                })}
                                                type="text"
                                                name="name"
                                                id="name"
                                                autoComplete="off"
                                                className="add-blc-ip"
                                                placeholder="Enter Name"
                                            />
                                        </div>
                                        <label className="error-text1">
                                            {errors?.name?.type == "required" &&
                                                "Name required "}{" "}
                                        </label>

                                        <div className="ip-inr-main mar-t-b-5">
                                            <label
                                                style={{ width: "260px" }}
                                                className="label">
                                                Amount
                                            </label>
                                            <input
                                                {...register("amount", {
                                                    required: true,
                                                    pattern: /^([0-9])+$/i,
                                                })}
                                                type="text"
                                                name="amount"
                                                id="amount"
                                                autoComplete="off"
                                                className="add-blc-ip"
                                                placeholder="Enter Amount"
                                            />
                                        </div>
                                        <label className="error-text1">
                                            {errors?.amount?.type ==
                                                "required" &&
                                                "Amount is required "}{" "}
                                            {errors?.amount?.type ==
                                                "pattern" &&
                                                "Amount Invalid"}{" "}
                                        </label>
                                        <div className="ip-inr-main mar-t-b-5">
                                            <label
                                                style={{ width: "260px" }}
                                                className="label">
                                                Email
                                            </label>
                                            <input
                                                {...register("emailId", {
                                                    required: true,
                                                    pattern: /\S+@\S+\.\S+/,
                                                })}
                                                type="email"
                                                name="emailId"
                                                id="emailId"
                                                autoComplete="off"
                                                className="add-blc-ip"
                                                placeholder="Enter EmailID"
                                            />
                                        </div>
                                        <label className="error-text1">
                                            {errors?.emailId?.type ==
                                                "required" &&
                                                "Email required "}{" "}
                                            {errors?.emailId?.type ==
                                                "pattern" &&
                                                "Email Invalid"}{" "}
                                        </label>
                                        <div className="ip-inr-main mar-t-b-5">
                                            <label
                                                style={{ width: "260px" }}
                                                className="label">
                                                Mobile Number
                                            </label>
                                            <input
                                                maxLength={10}
                                                {...register("mobileNumber", {
                                                    required: true,
                                                    pattern: /^([0-9]{10})+$/i,
                                                })}
                                                type="text"
                                                name="mobileNumber"
                                                id="mobileNumber"
                                                autoComplete="off"
                                                className="add-blc-ip"
                                                placeholder="Enter Contact Number"
                                            />
                                        </div>
                                        <label className="error-text1">
                                            {errors?.mobileNumber?.type ==
                                                "required" &&
                                                "Contact number is required "}{" "}
                                            {errors?.mobileNumber?.type ==
                                                "pattern" &&
                                                "Mobile Number Invalid"}{" "}
                                        </label>
                                        <div className="ip-inr-main mar-t-b-5">
                                            <label
                                                style={{ width: "260px" }}
                                                className="label">
                                                Notes
                                            </label>
                                            <input
                                                {...register("notes")}
                                                type="text"
                                                name="notes"
                                                id="notes"
                                                autoComplete="off"
                                                className="add-blc-ip"
                                                placeholder="Enter Notes"
                                            />
                                        </div>
                                        {/* <label className="error-text1">{errors?.notes?.type == 'required' && "Notes required "}  </label> */}

                                        <PulseLoader
                                            color={"#010080"}
                                            loading={loading}
                                            css={override}
                                            size={20}
                                        />
                                        <div className="ip-inr-main-btn mar-t-b-5 d-flex">
                                            <button
                                                type="submit"
                                                style={{ margin: "15px auto" }}
                                                className="btn add-btn">
                                                PAY
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PayWithoutSignin;

import React from "react";
import "./App.css";

function TermsAndConditions() {
    return (
        <div className="terms-main">
            <h1> Terms and Conditions</h1>
            <div>
                OVERVIEW This document prescribes the “Terms and Conditions”,
                (hereinafter referred to as “TOS” or “Terms of Service”) which
                shall be applicable to all transactions initiated by the User
                through the Paygen Pro application( hereinafter referred to as
                “Paygen App” or “THE APP”) developed by Paygen Pro (hereinafter
                referred to as “Paygen Pro” – which term shall encompass Paygen
                Pro, its parent, subsidiaries, predecessors, successors,
                affiliates, partners, officers, directors, agents, contractors,
                licensors, service providers, subcontractors, suppliers, interns
                and employees). User shall mean anyone who downloads or installs
                or otherwise uses the App. The User is advised to carefully read
                and understand these Terms and Conditions before using the
                Paygen App. By downloading the App, the User shall be deemed to
                have read, understood and accepted the TOS, mentioned herein
                below and will be bound by the TOS. The TOS may amended from
                time to time, the latest TOS will be displayed on Paygen’s Pro
                website. The continued use of The App constitutes acceptance by
                the User of any such amendment to the TOS. The TOS shall apply
                to all users of the App, including without limitation users who
                have installed The App, downloaded The App, vendors, customers,
                merchants, and/ or contributors of content. Please read the TOS
                carefully before accessing or using our App. By accessing or
                using any part of the App, you agree to be bound by the TOS. If
                you do not agree to all the conditions of this agreement, then
                This website is operated by Givit Infomatics Pvt Ltd(Paygen Pro)
                Throughoutches the site, the terms “we”, “us” and “our” refer to
                Givit Infomatics Pvt Ltd(Paygen Pro).Givit Infomatics Pvt
                Ltd(Paygen Pro) offers this website , including all information,
                tools and services available from this site to you, the user,
                conditioned upon your acceptance of all terms, conditions,
                policies and notices stated here.By visiting our site and and/
                or purchasing something from us, you engage in our “Service” and
                agree to be bound by the following terms and conditions (“Terms
                of Service”, “Terms”), including those additional terms and
                conditions and policies referenced herein and/or available by
                hyperlink. These Terms of Service apply to all users of the site
                . Please read these Terms of Service carefully before accessing
                or using our website . By accessing or using any part of the
                site , you agree to be bound by these Terms of Service. If you
                do not agree to all the terms and conditions of this agreement,
                then you may not access the website or use any services. If
                these Terms of Service are considered an offer, acceptance is
                expressly limited to these Terms of Service.Any new features or
                tools which are added to the current store shall also be subject
                to the Terms of Service. You can review the most current version
                of the Terms of Service at any time on this page. We reserve the
                right to update, change or replace any part of these Terms of
                Service by posting updates and/or changes to our website. It is
                your responsibility to check this page periodically for changes.
                Your continued use of or access to the website following the
                posting of any changes constitutes acceptance of those changes
                <p className="terms-sub"> SECTION 1 - ONLINE SERVICES TERMS</p>
                By agreeing to these Terms of Service, you represent that you
                are at least the age of majority in your state or province of
                residence, or that you are the age of majority in your state or
                province of residence and you have given us your consent to
                allow any of your minor dependants to use this site.You may not
                use our products for any illegal or unauthorized purpose nor may
                you, in the use of the Service, violate any laws in your
                jurisdiction (including but not limited to copyright laws).You
                must not transmit any worms or viruses or any code of a
                destructive nature.A breach or violation of any of the Terms
                will result in an immediate termination of your Services.
                <p className="terms-sub"> SECTION 2 - GENERAL CONDITIONS </p>
                We reserve the right to refuse service to anyone for any reason
                at any time.You understand that your content (not including
                credit card information), may be transferred unencrypted and
                involve (a) transmissions over various networks; and (b) changes
                to confirm and adapt to technical requirements of connecting
                networks or devices. Credit card information is always encrypted
                during transfer over networks.You agree not to reproduce,
                duplicate, copy, sell, resell or exploit any portion of the
                Service, use of the Service, or access to the Service or any
                contact on the website through which the service is provided,
                without express written permission by us.The headings used in
                this agreement are included for convenience only and will not
                limit or otherwise affect these Terms.
                <p className="terms-sub">
                    SECTION 3 - ACCURACY, COMPLETENESS AND TIMELINESS OF
                    INFORMATION
                </p>
                We are not responsible if information made available on this
                site is not accurate, complete or current. The material on this
                site is provided for general information only and should not be
                relied upon or used as the sole basis for making decisions
                without consulting primary, more accurate, more complete or more
                timely sources of information. Any reliance on the material on
                this site is at your own risk.This site may contain certain
                historical information. Historical information, necessarily, is
                not current and is provided for your reference only. We reserve
                the right to modify the contents of this site at any time, but
                we have no obligation to update any information on our site. You
                agree that it is your responsibility to monitor changes to our
                site and.
                <p className="terms-sub">
                    SECTION 4 - MODIFICATIONS TO THE SERVICE AND PRICES
                </p>
                Prices for our products are subject to change without notice.We
                reserve the right at any time to modify or discontinue the
                Service (or any part or content thereof) without notice at any
                time.We shall not be liable to you or to any third-party for any
                modification, price change, suspension or discontinuance of the
                Service.
                <p className="terms-sub">
                    SECTION 5 - ACCURACY OF BILLING AND ACCOUNT INFORMATION
                </p>
                You agree to provide current, complete and accurate purchase and
                account information for all purchases made at our website. You
                agree to promptly update your account and other information,
                including your email address and credit card numbers and
                expiration dates, so that we can complete your transactions and
                contact you as needed.
                <p className="terms-sub">SECTION 6 - OPTIONAL TOOLS</p>
                We may provide you with access to third-party tools over which
                we neither monitor nor have any control nor input.You
                acknowledge and agree that we provide access to such tools ”as
                is” and “as available” without any warranties, representations
                or conditions of any kind and without any endorsement. We shall
                have no liability whatsoever arising from or relating to your
                use of optional third-party tools.Any use by you of optional
                tools offered through the site is entirely at your own risk and
                discretion and you should ensure that you are familiar with and
                approve of the terms on which tools are provided by the relevant
                third-party provider(s).We may also, in the future, offer new
                services and/or features through the website (including, the
                release of new tools and resources). Such new features and/or
                services shall also be subject to these Terms of Service.
                <p className="terms-sub">SECTION 7 - THIRD-PARTY LINKS</p>
                Certain content, products and services available via our Service
                may include materials from third-parties.Third-party links on
                this site may direct you to third-party websites that are not
                affiliated with us. We are not responsible for examining or
                evaluating the content or accuracy and we do not warrant and
                will not have any liability or responsibility for any
                third-party materials or websites, or for any other materials,
                products, or services of third-parties.We are not liable for any
                harm or damages related to the purchase or use of goods,
                services, resources, content, or any other transactions made in
                connection with any third-party websites. Please review
                carefully the third-party's policies and practices and make sure
                you understand them before you engage in any transaction.
                Complaints, claims, concerns, or questions regarding third-party
                products should be directed to the third-party.
                <p className="terms-sub">
                    SECTION 8 - USER COMMENTS, FEEDBACK AND OTHER SUBMISSIONS
                </p>
                If, at our request, you send certain specific submissions (for
                example contest entries) or without a request from us you send
                creative ideas, suggestions, proposals, plans, or other
                materials, whether online, by email, by postal mail, or
                otherwise (collectively, 'comments'), you agree that we may, at
                any time, without restriction, edit, copy, publish, distribute,
                translate and otherwise use in any medium any comments that you
                forward to us. We are and shall be under no obligation
                <ol>
                    <li>to maintain any comments in confidence;</li>
                    <li> to pay compensation for any comments or;</li>
                    <li> to respond to any comments</li>
                </ol>
                We may, but have no obligation to, monitor, edit or remove
                content that we determine in our sole discretion are unlawful,
                offensive, threatening, libellous, defamatory, pornographic,
                obscene or otherwise objectionable or violates any party’s
                intellectual property or these Terms of Service.You agree that
                your comments will not violate any right of any third-party,
                including copyright, trademark, privacy, personality or other
                personal or proprietary right. You further agree that your
                comments will not contain libellous or otherwise unlawful,
                abusive or obscene material, or contain any computer virus or
                other malware that could in any way affect the operation of the
                Service or any related website. You may not use a false e-mail
                address, pretend to be someone other than yourself, or otherwise
                mislead us or third-parties as to the origin of any comments.
                You are solely responsible for any comments you make and their
                accuracy. We take no responsibility and assume no liability for
                any comments posted by you or any third-party.
                <p className="terms-sub">SECTION 9 - PERSONAL INFORMATION</p>
                Your submission of personal information through the website is
                governed by our Privacy Policy.
                <p className="terms-sub">
                    SECTION 10 - ERRORS, INACCURACIES AND OMISSIONS
                </p>
                Occasionally there may be information on our site or in the
                Service that contains typographical errors, inaccuracies or
                omissions that may relate to product descriptions, pricing,
                promotions, offers, product shipping charges, transit times and
                availability. We reserve the right to correct any errors,
                inaccuracies or omissions, and to change or update information
                or cancel orders if any information in the Service or on any
                related websit is inaccurate at any time without prior notice
                (including after you have submitted your order).We undertake no
                obligation to update, amend or clarify information in the
                Service or on any related website, including without limitation,
                pricing information, except as required by law. No specified
                update or refresh date applied in the Service or on any related
                website, should be taken to indicate that all information in the
                Service or on any related website has been modified or updated.
                <p className="terms-sub">SECTION 11 - PROHIBITED USES</p>
                In addition to other prohibitions as set forth in the Terms of
                Service, you are prohibited from using the site or its content:
                <ol type="A">
                    <li>
                        In addition to other prohibitions as set forth in the
                        Terms of Service, you are prohibited from using the site
                        or its content:
                    </li>
                    <li>
                        to solicit others to perform or participate in any
                        unlawful acts;
                    </li>
                    <li>
                        to violate any international, federal, provincial or
                        state regulations, rules, laws, or local ordinances;
                    </li>
                    <li>
                        to infringe upon or violate our intellectual property
                        rights or the intellectual property rights of others
                    </li>
                    <li>
                        to harass, abuse, insult, harm, defame, slander,
                        disparage, intimidate, or discriminate based on gender,
                        sexual orientation, religion, ethnicity, race, age,
                        national origin, or disability;
                    </li>
                    <li>to submit false or misleading information;</li>
                    <li>
                        to upload or transmit viruses or any other type of
                        malicious code that will or may be used in any way that
                        will affect the functionality or operation of the
                        Service or of any related website, other websites, or
                        the Internet;
                    </li>
                    <li>
                        to collect or track the personal information of others;
                    </li>
                    <li>
                        to spam, phis, pharm, pretext, spider, crawl, or scrape;
                    </li>
                    <li> for any obscene or immoral purpose; or</li>
                    <li>
                        to interfere with or circumvent the security features of
                        the Service or any related website, other websites, or
                        the Internet. We reserve the right to terminate your use
                        of the Service or any related website for violating any
                        of the prohibited uses.
                    </li>
                </ol>
                <p className="terms-sub">
                    SECTION 12 - DISCLAIMER OF WARRANTIES; LIMITATION OF
                    LIABILITY
                </p>
                We do not guarantee, represent or warrant that your use of our
                service will be uninterrupted, timely, secure or error-free.We
                do not warrant that the results that may be obtained from the
                use of the service will be accurate or reliable.You agree that
                from time to time we may remove the service for indefinite
                periods of time or cancel the service at any time, without
                notice to you.You expressly agree that your use of, or inability
                to use, the service is at your sole risk. The service and all
                products and services delivered to you through the service are
                (except as expressly stated by us) provided 'as is' and 'as
                available' for your use, without any representation, warranties
                or conditions of any kind, either express or implied, including
                all implied warranties or conditions of merchantability,
                merchantable quality, fitness for a particular purpose,
                durability, title, and non-infringement.In no case shall Givit
                Infomatics Pvt Ltd(Paygen Pro) our directors, officers,
                employees, be liable for any injury, loss, claim, or any direct,
                indirect, incidental, punitive, special, or consequential
                damages of any kind, including, without limitation lost profits,
                lost revenue, lost savings, loss of data, replacement costs, or
                any similar damages, whether based in contract, tort (including
                negligence), strict liability or otherwise, arising from your
                use of any of the service or any products procured using the
                service, or for any other claim related in any way to your use
                of the service or any product, including, but not limited to,
                any errors or omissions in any content, or any loss or damage of
                any kind incurred as a result of the use of the service or any
                content (or product) posted, transmitted, or otherwise made
                available via the service, even if advised of their possibility.
                Because some states or jurisdictions do not allow the exclusion
                or the limitation of liability for consequential or incidental
                damages, in such states or jurisdictions, our liability shall be
                limited to the maximum extent permitted by law
                <p className="terms-sub">
                    SECTION 13 - ACCOUNTABILITY OF THE COMPANY
                </p>
                In the event of a dispute/Chargeback, Givit Infomatics Private
                Limited records should constitute indisputable evidence of the
                transactions carried out through the use of the user's account
                and financial holdings. <br />
                The user is entirely responsible for any information provided by
                him or her, including his or her bank card data and any other
                papers he or she provides. The company cannot be held liable for
                any disputes or erroneous payments made by the user. In the
                event of applicable faults or any other genuine disagreement,
                the company will consider cancellation or reimbursement. <br />
                If the company has to commence any refunds in the user's
                account. In that case, the company will notify him or her. The
                company will initiate the refund procedure in a few business
                days after ultimately confirming the refund method and verifying
                the legitimacy of the reason supplied by the user. <br />
                All of the procedures provided here are in full compliance with
                Givit Infomatics Private Limited's most recent laws and
                regulations. They will not accept any deceptive practices used
                by consumers to gain refunds. Users are expected to adhere to
                the company's general terms and conditions and are bound by the
                company's criteria.
            </div>
        </div>
    );
}

export default TermsAndConditions;

import React, { useState, useEffect } from "react";
import "./dashboard.css";
import { useForm } from "react-hook-form";
import DashboardNav from "./dashboardNav";
import LoginServices from "../../services/LoginServices";
import Billpayment from "./billpaymentNav";
import { css } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { FaUserCircle } from "react-icons/fa";
const override = css`
    display: block;
    margin: 0 auto;
    border-color: "#010080";
`;

function BillpaymentBills() {
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();
    const [submitEnabler, setSubmitEnabler] = useState<any>(false);
    const [dropdown, setDropDown] = useState<any>();
    const selectBillerHandler = (e: any) => {
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        LoginServices.FetchBillerListByCategoryName(
            config,
            e.target.value,
            1
        ).then((response) => {
            setSubmitEnabler(true);
            console.log("res", response);
            setDropDown(response?.data?.BillersList);
        });
    };
    const [refreshP, setRefreshP] = useState<any>("");
    const [walletBlc, setWalletBlc] = useState<any>(0);
    const userId = localStorage.getItem("userid");
    const Navigate = useNavigate();
    const BillpayHandler = (e: any) => {
        if (e.currentTarget.id == "Insurance") {
            Navigate("/dashboard/bill-payments/insurance");
            return;
        }
        Navigate(
            `/dashboard/bill-payments/paybill-fetch/${e.currentTarget.id}`
        );
    };
    const biller = [
        // "Broadband Postpaid",
        // "Cable Tv",
        // "Clubs and Associations",
        "Credit Card",
        // "DTH",
        "Electricity",
        // "Fastag",
        "Gas",
        // "Insurance",
        // "Hospital and pathology",
        // "Landline Postpaid",
        // "Loan Repayment",
        // "LPG Gas",
        // "Subscription",
        "Education Fees",
        // "Municipal Services",
        // "Municipal Taxes",
        // "Mobile Postpaid",
        // "Mobile Prepaid",
        "Water",
        // "Housing Society",
    ];

    useEffect(() => {
        if (userId) {
            let config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            };
            LoginServices.getWalletBalanceByUserID(parseInt(userId), config)
                .then((response: any) => {
                    console.log(response);
                    if (response.data.Status == 1) {
                        setWalletBlc(response?.data?.walletBalance);
                    } else {
                        alert(response?.data?.Message);
                    }

                    console.log(response.data);
                })
                .catch((e: Error) => {
                    console.log(e);
                });
        } else {
            alert("Login to see your dashboard");
            Navigate("/");
        }
    }, [refreshP == "yes"]);
    // showSection == 'biller-list'
    const Submithandler = (data: any) => {
        console.log(data);
        sessionStorage.setItem("BillerCat", data.BillerCat);
        sessionStorage.setItem("Biller", data.Biller);
        Navigate("/dashboard/bill-payments/quick-pay/details");
    };
    return (
        <div className="main-bg">
            <div>
                <DashboardNav active={"bill-payments"}></DashboardNav>
            </div>
            <div className="paymentWrapper">
                <div className="payment-header">
                    <h2>Bill Payments</h2>
                    <img
                        className="payment-header-icon"
                        src="assets/billpayment/billpayment_header.png"
                        alt=""
                    />
                </div>
            </div>

            <div>
                <div
                    style={{
                        background: "#F6F7FF",
                        padding: "1rem 0rem",
                    }}>
                    <div className="payment-wrapper">
                        <Billpayment active="QuickPay" />
                    </div>
                </div>
                <div style={{ backgroundColor: "#f6f7ff" }}>
                    <div className="paymentWrapper"></div>
                    <div className="biller-details-wrapper">
                        <form
                            className="biller-details"
                            id="quickpay"
                            onSubmit={handleSubmit(Submithandler)}>
                            <div className="biller-detail">
                                <img
                                    src="/assets/img/billerName.svg"
                                    className="call-icon"
                                    alt=""
                                />
                                <select
                                    className="select"
                                    defaultValue=""
                                    // onChange={selectBillerHandler}
                                    {...register("BillerCat", {
                                        required:
                                            "Please select Biller Category",
                                        onChange: (e: any) => {
                                            selectBillerHandler(e);
                                        },
                                    })}>
                                    <option value="" disabled>
                                        Select Biller Category
                                    </option>
                                    {biller.map((item: any) => {
                                        return (
                                            <option value={item}>{item}</option>
                                        );
                                    })}
                                </select>
                            </div>
                            {submitEnabler && (
                                <>
                                    <div className="biller-detail ">
                                        <FaUserCircle
                                            size={35}
                                            className="icon"
                                        />
                                        <select
                                            className={`select ${
                                                errors?.Biller && "input-error"
                                            }`}
                                            defaultValue={""}
                                            {...register("Biller", {
                                                required:
                                                    "Please select Biller",
                                            })}>
                                            <option value="" disabled>
                                                Biller Name
                                            </option>
                                            {dropdown?.map((item: any) => (
                                                <option value={item?.blr_id}>
                                                    {" "}
                                                    {item?.blr_name}
                                                </option>
                                            ))}
                                        </select>
                                        {errors?.Biller && (
                                            <p style={{ color: "red" }}>
                                                {" "}
                                                {errors?.Biller.message}
                                            </p>
                                        )}
                                    </div>
                                    <button type="submit">Submit</button>
                                </>
                            )}
                        </form>
                    </div>
                </div>
            </div>
            <div>
                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </div>
        </div>
    );
}

export default BillpaymentBills;

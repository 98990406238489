import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import DashboardNav from "./dashboardNav";
import "./dashboard.css";
import WalletBlcHeader from "./walletBlcHeader";
import LoginServices from "../../services/LoginServices";
import Razor from "./Razor";
import { Modal, Row, Col } from "react-bootstrap";
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import { PulseLoader } from "react-spinners";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPaginate from "react-paginate";
import { GrClose } from "react-icons/gr";
import { AiOutlineClose } from "react-icons/ai";
const override = css`
    display: block;
    margin: 0 auto;
    border-color: "#010080";
`;

function PayBills() {
    let [loading, setLoading] = useState(false);
    let [isChecked, setIsChecked] = useState(
        localStorage.getItem("instantPayoutsOn") == "1" ? 1 : 2
    );
    const inputref = React.useRef<HTMLInputElement | null>(null);
    const formref = React.useRef<HTMLFormElement | null>(null);
    const [pageNumber, setPageNumber] = useState(0);
    const [showclose, setshowclose] = useState<any>(false);
    const [forcepage, setforcepage] = useState<any>();
    const productsPerPage = 40;
    let pagesVisited = pageNumber * productsPerPage;
    const [payoutPurpose, setPayoutPurpose] = useState<any>("");
    const [ChargeAmount, setChargeamount] = useState<any>(0);
    const navigate = useNavigate();

    const [pageCount, setPageCount] = useState(1);
    const [refreshP, setRefreshP] = useState<any>("");
    const {
        register,
        formState: { errors },
        handleSubmit,
        reset,
    } = useForm();
    const notify = () => toast("Wow so easy!");
    const acceptChargeChange = (e: any) => {
        console.log(e.target.value);
        setIsChecked(e.target.value);
    };
    const inputchange = (e: any) => {
        if (e.target.value.length > 0) {
            setshowclose(true);
        } else setshowclose(false);
    };
    const onSubmit = (data: any) => {
        console.log(data);
        const payload: any = {
            userId: userId,
            name: data.name,
            mobileNumber: data.mobileNumber,
            accountNumber: data.accountNumber,
            IFSCCode: data.IFSCCode,
            isVerificationRequired: 1,
        };
        /*   if (data.isVerificationRequired) {
            payload.isVerificationRequired = 1;
        } else {
            payload.isVerificationRequired = 0;
        } */
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        setLoading(true);
        LoginServices.createWalletContact(payload, config)
            .then((response: any) => {
                setLoading(false);
                console.log(response);
                if (response.data.Status == 1) {
                    alert(response.data.Message);
                    setShowSection("biller-list");
                    setRefreshP("yes");
                    reset();
                } else {
                    alert(response?.data?.Message);
                }

                console.log(response.data);
            })
            .catch((e: any) => {
                setLoading(false);
                console.log(e);
                alert(e?.response.data?.Message);
            });
    };
    const [walletBlc, setWalletBlc] = useState<any>(0);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [currentPayee, setCurrentPayee] = useState<any>();
    const [userIpAddress, setUserIpAddress] = useState("");
    const [payAmount, setPayAmount] = useState<any>();
    const [payNotes, setPayNotes] = useState<any>();

    const [addBlc, setAddBlc] = useState<number>();
    const userId = localStorage.getItem("userid");
    const [payoutList, setPayoutList] = useState<any>();
    const [SearchpayoutList, setSearchPayoutList] = useState<any>();
    const [showSection, setShowSection] = useState<any>("add-img");
    // add-img , add-form, biller-list,

    // let [user, setUser] = useState({
    //     userId: '',
    //     name: '',
    //     mobileNumber: '',
    //     accountNumber: '',
    //     IFSCCode: ''
    // });

    useEffect(() => {
        let percentage: any =
            isChecked == 1
                ? localStorage.getItem("payoutChargePercentage")
                : localStorage.getItem("payoutChargePercentage_TOne");
        let amount = (Number(payAmount) * Number(percentage)) / 100;
        setChargeamount(amount.toFixed(2));
    }, [payAmount, isChecked]);
    useEffect(() => {
        fetch("https://api.ipify.org/?format=json")
            .then((Response) => {
                return Response.json();
            })
            .then((response) => {
                console.log(response);
                setUserIpAddress(response.ip);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);
    useEffect(() => {
        if (userId) {
            let config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            };
            LoginServices.getWalletBalanceByUserID(parseInt(userId), config)
                .then((response: any) => {
                    console.log(response);
                    if (response.data.Status == 1) {
                        setWalletBlc(response?.data?.walletBalance);
                    } else {
                        alert(response?.data?.Message);
                    }

                    console.log(response.data);
                })
                .catch((e: Error) => {
                    console.log(e);
                });
            LoginServices.getPayoutContacts(parseInt(userId), config)
                .then((response: any) => {
                    console.log(response);
                    if (response.data.Status == 1) {
                        setPayoutList(response?.data?.Contacts);
                        setSearchPayoutList(response?.data?.Contacts);
                        // setPayoutList(response?.data?.Contacts);
                        setPageCount(
                            Math.ceil(
                                response?.data?.Contacts?.length /
                                    productsPerPage
                            )
                        );

                        if (response?.data?.Contacts?.length == 0) {
                            setShowSection("add-img");
                        } else {
                            setShowSection("biller-list");
                        }
                    } else {
                        alert(response?.data?.Message);
                    }
                })
                .catch((e: Error) => {
                    console.log(e);
                });
        } else {
            alert("Login to see your dashboard");
            navigate("/");
        }
    }, [refreshP == "yes"]);
    // showSection == 'biller-list'

    const sendPayment = (item: any) => {
        if (payoutPurpose?.length === 0) {
            alert("Payout purpose is required");
            return;
        }

        if (payAmount > walletBlc) {
            alert("Pay amount is more than your wallet balance");
            return;
        }
        if (payAmount > 200000) {
            alert("Pay amount is more than 2,00,000Rs");
            return;
        }

        const payload = {
            payout_contactId: item?.payout_contactId,
            amount: parseInt(payAmount),
            notes: payNotes,
            ipAddress: userIpAddress,
            payoutPurpose: payoutPurpose,
            settlementType: isChecked,
        };
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        setLoading(true);
        LoginServices.payToContact(payload, config)
            .then((response: any) => {
                console.log(response);
                if (response.data.Status == 1) {
                    setLoading(false);
                    // showSection('biller-list')
                    setRefreshP("yes");
                    setShowModal(false);
                    // alert(response?.data?.Message);
                    setPayoutPurpose("");
                    if (isChecked == 1) {
                        toast.success("Transaction completed!", {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else {
                        toast.success(
                            "Amount will be credited next working business day",
                            {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            }
                        );
                    }
                    navigate("/dashboard");
                    // notify();
                    // window.location.reload();
                } else {
                    setLoading(false);
                    alert(response?.data?.Message);
                }

                console.log(response.data);
            })
            .catch(function (error) {
                setLoading(false);
                alert(error.response.data.Message); // this is the part you need that catches 400 request
            });
    };
    const deletehandler = (e: any) => {
        e.stopPropagation();
        console.log("delete");
        if (window.confirm("Are you Sure you want delete Contact")) {
            if (userId) {
                let contactid = e?.currentTarget?.getAttribute("id");
                let config = {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "token"
                        )}`,
                    },
                };
                LoginServices.deletePayout(parseInt(userId), contactid, config)
                    .then((response: any) => {
                        console.log(response);
                        if (response.data.Status == 1) {
                            LoginServices.getPayoutContacts(
                                parseInt(userId),
                                config
                            ).then((response: any) => {
                                console.log(response);
                                if (response.data.Status == 1) {
                                    setPayoutList(response?.data?.Contacts);
                                    setSearchPayoutList(
                                        response?.data?.Contacts
                                    );
                                    // setPayoutList(response?.data?.Contacts);
                                    // pageCount = Math.ceil(
                                    //     SearchpayoutList?.length /
                                    //         productsPerPage
                                    // );
                                    setPageCount(
                                        Math.ceil(
                                            response?.data?.Contacts?.length /
                                                productsPerPage
                                        )
                                    );
                                    if (response?.data?.Contacts?.length == 0) {
                                        setShowSection("add-img");
                                    } else {
                                        setShowSection("biller-list");
                                    }
                                } else {
                                    alert(response?.data?.Message);
                                }
                            });

                            // setWalletBlc(response?.data?.walletBalance);
                        } else {
                            alert(response?.data?.Message);
                        }

                        console.log(response.data);
                    })
                    .catch((e: Error) => {
                        console.log(e);
                    });
            }
        }
    };
    console.log(pagesVisited);
    const displayProducts = SearchpayoutList?.slice(
        pagesVisited,
        pagesVisited + productsPerPage
    ).map((item: any) => {
        return (
            <div
                key={item?.bankAccountNumber}
                // col-lg-2 col-md-3
                className=" payee-col"
                style={{ maxWidth: "330px" }}>
                <div
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        console.log(item);
                        setCurrentPayee(item);
                        setShowModal(true);
                    }}
                    className="payee-col-sub"
                    style={{
                        cursor: "pointer",
                        position: "relative",
                    }}>
                    <button
                        className={"delete-payout"}
                        onClick={deletehandler}
                        id={item?.payout_contactId}>
                        <AiOutlineClose size={20} />
                    </button>
                    {/* <h1 className="first-letter">{item?.bankAccountName.charAt(0)}</h1> */}
                    <div className="d-flex card-img-name">
                        <div>
                            <img
                                className="card-avatar"
                                src="assets/img/user-avatar2.png"
                                alt=""
                            />
                        </div>
                        <div className="ac-name-wrapper">
                            <p className="ac-name">{item?.bankAccountName}</p>
                            <p className="ac-number">
                                a/c {item?.bankAccountNumber}
                            </p>
                            <p className="ac-number"> {item?.bankIFSCCode}</p>
                            <p className="ac-number">
                                Contact:{item?.mobileNumber}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    });
    // let pageCount = Math.ceil(SearchpayoutList?.length / productsPerPage);
    const changePage = (selected: any) => {
        setPageNumber(selected.selected);
        window.scrollTo({ top: 0, behavior: "smooth" });
    };
    const searchHandler = (e: any) => {
        e.preventDefault();

        if (inputref?.current?.value.length === 0) {
            // setSearchPayoutList(payoutList);
            return;
        }

        if (userId) {
            let config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            };
            let data = { keyword: inputref?.current?.value };

            setforcepage(0);

            setPageNumber(0);
            pagesVisited = 0;
            LoginServices.searchPayout(parseInt(userId), data, config)
                .then((response: any) => {
                    console.log(response);
                    if (response.data.Status == 1) {
                        // pageCount = Math.ceil(
                        //     SearchpayoutList?.length / productsPerPage
                        // );
                        setSearchPayoutList(response?.data?.Contacts);
                        setPageCount(
                            Math.ceil(
                                response?.data?.Contacts?.length /
                                    productsPerPage
                            )
                        );
                        // setWalletBlc(response?.data?.walletBalance);
                    } else {
                        alert(response?.data?.Message);
                    }

                    console.log(response.data);
                })
                .catch((e: Error) => {
                    console.log(e);
                });
        }
        console.log(inputref?.current?.value);
    };
    const cancelHandler = (e: any) => {
        e.preventDefault();
        setforcepage(0);
        setPageNumber(0);
        setPageCount(Math.ceil(payoutList?.length / productsPerPage));
        pagesVisited = 0;
        setSearchPayoutList(payoutList);
        // pageCount = Math.ceil(payoutList?.length / productsPerPage);
        formref?.current?.reset();
        setshowclose(false);
    };
    return (
        <>
            <div className="main-bg">
                <div>
                    <DashboardNav active={"pay-bills"}></DashboardNav>
                </div>
                <div>
                    {showSection == "add-img" && (
                        <div>
                            <div className="pay-bills-grp1">
                                <p className="pay-bills-head">
                                    {" "}
                                    {userId != "1104299"
                                        ? "Payouts"
                                        : "Biller List"}
                                </p>
                                <div className="d-flex">
                                    <button
                                        onClick={() =>
                                            setShowSection("biller-list")
                                        }
                                        className="see-billers-btn">
                                        {userId != "1104299"
                                            ? "Manage Beneficary"
                                            : "Manage Billers"}
                                    </button>
                                </div>
                            </div>
                            <div className="pay-bills-center">
                                <div>
                                    <div>
                                        <img
                                            className="pay-bills-main-img"
                                            src="assets/img/add_beneficary.svg"
                                            alt=""
                                        />
                                        <p className="add-your-p">
                                            {userId != "1104299"
                                                ? " Quick payouts by adding more beneficaries"
                                                : " Quick Billers by adding more beneficaries"}
                                        </p>
                                    </div>
                                </div>
                                <div>
                                    <div className="add-btn-div">
                                        <button
                                            onClick={() =>
                                                setShowSection("add-form")
                                            }
                                            className="add-biller-btn">
                                            {userId != "1104299"
                                                ? "Add Beneficary"
                                                : "Add Biller"}
                                        </button>
                                        {/* onClick={loginFormSubmit} */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {showSection == "biller-list" && (
                        <>
                            <div>
                                <div className="d-flex pay-bills-flex">
                                    <p className="pay-bills-head">
                                        {userId != "1104299"
                                            ? " Beneficary List"
                                            : "Billers List"}
                                    </p>
                                    <form
                                        ref={formref}
                                        className="search-payout grid1-main"
                                        style={{ padding: "0.6rem" }}>
                                        <input
                                            placeholder="Search by Name, Account Number or Mobile Number"
                                            ref={inputref}
                                            onChange={inputchange}
                                        />
                                        <div>
                                            <button
                                                className="search"
                                                type="submit"
                                                onClick={searchHandler}>
                                                Search
                                            </button>
                                            {showclose && (
                                                <button
                                                    className="close"
                                                    type="button"
                                                    onClick={cancelHandler}>
                                                    <GrClose />
                                                </button>
                                            )}
                                        </div>
                                    </form>
                                    <div className="d-flex">
                                        <button
                                            onClick={() =>
                                                setShowSection("add-form")
                                            }
                                            className="see-billers-btn">
                                            {userId != "1104299"
                                                ? "Add Beneficary"
                                                : "Add Biller"}
                                        </button>
                                    </div>
                                </div>

                                <div
                                    className="grid1-main "
                                    style={{ padding: "32px 0", width: "95%" }}>
                                    <div className="row grid-layout">
                                        {SearchpayoutList?.length != 0 &&
                                            displayProducts}

                                        {/* {payoutList?.length == 0 &&
                                    <h2>No Contacts Found</h2>
                                    } */}

                                        {SearchpayoutList?.length == 0 && (
                                            <h2>
                                                {" "}
                                                {userId != "1104299"
                                                    ? "No Beneficaries, Please add"
                                                    : "No Billers, Please add"}
                                            </h2>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {SearchpayoutList?.length > 0 && (
                                <div
                                    style={{
                                        display: "grid",
                                        justifyItems: "center",
                                        marginTop: "2rem",
                                        width: "100%",
                                    }}>
                                    <ReactPaginate
                                        pageCount={pageCount}
                                        // forcePage={1}

                                        onPageChange={changePage}
                                        forcePage={forcepage}
                                        breakClassName={"page-item"}
                                        breakLinkClassName={"page-link"}
                                        containerClassName={"pagination"}
                                        pageClassName={"page-item"}
                                        pageLinkClassName={"page-link"}
                                        previousClassName={"page-item"}
                                        previousLinkClassName={"page-link"}
                                        nextClassName={"page-item"}
                                        nextLinkClassName={"page-link"}
                                        activeClassName={"active"}
                                    />
                                </div>
                            )}
                        </>
                    )}

                    {showSection == "add-form" && (
                        <div>
                            <div className="pay-bills-grp1">
                                <p className="pay-bills-head">Add Beneficary</p>
                                <div className="d-flex">
                                    <button
                                        onClick={() =>
                                            setShowSection("biller-list")
                                        }
                                        className="see-billers-btn">
                                        {userId != "1104299"
                                            ? "Manage Beneficaries"
                                            : "Manage Billers"}
                                    </button>
                                </div>
                            </div>

                            <div className="form-main">
                                <form
                                    className="form-sub"
                                    onSubmit={handleSubmit(onSubmit)}>
                                    {/* onChange={(e:any)=>setAddBlc(e.target.value)} */}
                                    {/* <input {...register("name", { required: true })} name="name" id="name" className="pay-bill-ip" type="text" placeholder="Enter Name" />
                                    <label className="error-text"> {errors?.name && "Name is required"} </label> */}

                                    <input
                                        maxLength={30}
                                        {...register("name", {
                                            required: true,
                                        })}
                                        name="name"
                                        id="name"
                                        className="pay-bill-ip"
                                        type="text"
                                        placeholder="Enter Name"
                                    />
                                    <label className="error-text">
                                        {errors?.name?.type == "required" &&
                                            "Name is required "}{" "}
                                    </label>

                                    <input
                                        maxLength={10}
                                        {...register("mobileNumber", {
                                            required: true,
                                            pattern: /^([0-9]{10})+$/i,
                                        })}
                                        name="mobileNumber"
                                        id="mobileNumber"
                                        className="pay-bill-ip"
                                        type="text"
                                        placeholder="Enter Mobile Number"
                                    />
                                    <label className="error-text">
                                        {errors?.mobileNumber?.type ==
                                            "required" &&
                                            "Mobile number is required "}{" "}
                                        {errors?.mobileNumber?.type ==
                                            "pattern" &&
                                            "Mobile Number Invalid"}{" "}
                                    </label>

                                    <input
                                        {...register("accountNumber", {
                                            required: true,
                                            pattern: /^[0-9]+$/i,
                                        })}
                                        name="accountNumber"
                                        id="accountNumber"
                                        className="pay-bill-ip"
                                        type="text"
                                        placeholder="Enter Bank Account Number"
                                    />
                                    <label className="error-text">
                                        {errors?.accountNumber?.type ==
                                            "required" &&
                                            "Bank Account number is required "}{" "}
                                        {errors?.accountNumber?.type ==
                                            "pattern" &&
                                            "Account Number Invalid"}{" "}
                                    </label>

                                    <input
                                        {...register("IFSCCode", {
                                            required: true,
                                            pattern:
                                                /^[A-Za-z]{4}0[A-Z0-9a-z]{6}$/i,
                                        })}
                                        name="IFSCCode"
                                        id="IFSCCode"
                                        className="pay-bill-ip"
                                        type="text"
                                        placeholder="Enter IFSC Code"
                                    />
                                    <label className="error-text">
                                        {errors?.IFSCCode?.type == "required" &&
                                            "IFSC code is required "}{" "}
                                        {errors?.IFSCCode?.type == "pattern" &&
                                            "IFSC code Invalid"}{" "}
                                    </label>
                                    <label>
                                        {/* <input
                                            type="checkbox"
                                            {...register(
                                                "isVerificationRequired"
                                            )}
                                        /> */}
                                        <span
                                            style={{
                                                color: "red",
                                                fontSize: "0.95rem",
                                            }}>
                                            Note: Rs 4 wil be deducted as
                                            processing Fees
                                        </span>
                                    </label>

                                    {/* <div className="form-check">
                                        <input {...register("isVerificationRequired")}
                                            className="form-check-input" type="checkbox" value="" id="isVerificationRequired" />
                                        <label className="form-check-label" >
                                            Is Verification Required
                                        </label>
                                    </div> */}

                                    <PulseLoader
                                        color={"#010080"}
                                        loading={loading}
                                        css={override}
                                        size={20}
                                    />

                                    <div
                                        className="d-flex"
                                        style={{ marginTop: "20px" }}>
                                        <button
                                            type="submit"
                                            className="add-biller-btn">
                                            {userId != "1104299"
                                                ? "Add Beneficary"
                                                : "Add Billers"}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    )}

                    <Modal
                        show={showModal}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        dialogClassName="c-modal-pay-bills"
                        onHide={() => setShowModal(false)}>
                        {/* <Modal.Header closeButton>
        
      </Modal.Header> */}
                        <Modal.Body>
                            <div className="m-name">
                                <p className="m-name-payee">
                                    {currentPayee?.bankAccountName}
                                </p>
                                <p className="m-mobile-payee">
                                    {currentPayee?.mobileNumber}
                                </p>
                                <p className="m-mobile-payee">
                                    A/C {currentPayee?.bankAccountNumber}
                                </p>
                                <p className="m-mobile-payee">
                                    {" "}
                                    {currentPayee?.bankIFSCCode}
                                </p>
                                <div
                                    className="pay-ip-div"
                                    style={{ display: "grid", gap: "0.7rem" }}>
                                    <input
                                        onChange={(e: any) =>
                                            setPayAmount(e.target.value)
                                        }
                                        name="amount"
                                        className="pay-ip-div-e1"
                                        type="number"
                                        autoComplete="off"
                                        placeholder="Please Enter Amount"
                                    />
                                    <select
                                        defaultValue=""
                                        onChange={(e: any) => {
                                            setPayoutPurpose(e.target.values);
                                        }}
                                        style={{ display: "unset" }}>
                                        <option value="" disabled>
                                            Payout purpose
                                        </option>
                                        <option value="Education Fee">
                                            Education Fee
                                        </option>
                                        <option value="Vendor Payment">
                                            Vendor Payment
                                        </option>
                                        <option value="Housing Society">
                                            Housing Society
                                        </option>
                                        <option value="Rental Payment">
                                            Rental Payment
                                        </option>
                                        <option value="Credit Card bill payment">
                                            Credit Card bill payment
                                        </option>
                                    </select>
                                    <div className="acceptPayoutcharge-wrapper">
                                        <div className="acceptPayoutcharge">
                                            <input
                                                type={"radio"}
                                                // value={isChecked}
                                                id="T1"
                                                name="PaymentType"
                                                value={"2"}
                                                checked={isChecked == 2}
                                                onChange={acceptChargeChange}
                                                style={{
                                                    opacity: 1,
                                                    display: "block",
                                                    marginTop: "1rem",
                                                    pointerEvents: "unset",
                                                }}
                                            />
                                            <label htmlFor="T1">
                                                T+1 (
                                                {localStorage.getItem(
                                                    "payoutChargePercentage_TOne"
                                                )}
                                                % )
                                            </label>
                                        </div>
                                        {localStorage.getItem(
                                            "instantPayoutsOn"
                                        ) == "1" && (
                                            <div className="acceptPayoutcharge">
                                                <input
                                                    type={"radio"}
                                                    // value={isChecked}
                                                    id="instant"
                                                    name="PaymentType"
                                                    checked={isChecked == 1}
                                                    value={"1"}
                                                    onChange={
                                                        acceptChargeChange
                                                    }
                                                    style={{
                                                        opacity: 1,
                                                        display: "block",
                                                        marginTop: "1rem",
                                                        pointerEvents: "unset",
                                                    }}
                                                />
                                                <label htmlFor="instant">
                                                    Instant Payment (
                                                    {localStorage.getItem(
                                                        "payoutChargePercentage"
                                                    )}
                                                    % )
                                                </label>
                                            </div>
                                        )}
                                    </div>
                                    <p
                                        style={{
                                            color: "darkred",
                                            fontSize: "0.9rem",
                                        }}>
                                        {payAmount &&
                                            `Note:An Amount of Rs
                                        ${
                                            Number(ChargeAmount) + 15
                                        }(RS.15 IMPS charges + RS.${ChargeAmount}  Platform fees Inc GST) is charged as processing
                                        Fee`}
                                    </p>
                                    {loading == false && (
                                        <button
                                            style={{
                                                marginTop: "1rem",
                                                backgroundColor: "#010080",
                                                color: "white",
                                                border: "none",
                                                padding: "0.5rem 1rem",
                                            }}
                                            onClick={() => {
                                                sendPayment(currentPayee);
                                            }}>
                                            Proceed
                                        </button>
                                    )}
                                    <PulseLoader
                                        color={"#010080"}
                                        loading={loading}
                                        css={override}
                                        size={20}
                                    />

                                    {/* </div> */}
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                    <ToastContainer
                        position="top-center"
                        autoClose={5000}
                        hideProgressBar
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </div>
            </div>
        </>
    );
}

export default PayBills;

import React from "react";
import "./dashboard.css";
import { NavLink } from "react-router-dom";
let BillpaymentNav = (props: any) => {
    const active = props.active;
    return (
        <div className="paymentNav">
            <NavLink
                className={
                    active == "billpay" ? "billNavactive" : "billNavinactive"
                }
                to="/dashboard/bill-payments">
                Pay Bills
            </NavLink>
            <NavLink
                className={
                    active == "QuickPay" ? "billNavactive" : "billNavinactive"
                }
                to="/dashboard/bill-payments/quick-pay">
                Quick Pay
            </NavLink>
            <NavLink
                className={
                    active == "complaints" ? "billNavactive" : "billNavinactive"
                }
                to="/dashboard/bill-payments/complaints">
                Complaints
            </NavLink>
            <NavLink
                className={
                    active == "transaction"
                        ? "billNavactive"
                        : "billNavinactive"
                }
                to="/dashboard/bill-payments/billpaymentTransaction">
                Bill Payment Transactions
            </NavLink>
        </div>
    );
};

export default BillpaymentNav;

import { config } from "process";
import http from "../http-common";

const loginWithEmailPassword = (data: any) => {
    return http.post("/users/login/email", data);
};
const loginWithPhonePassword = (data: any) => {
    return http.post("/users/login", data);
};
const loginWithOtp = (data: any) => {
    return http.post("/users/login/verifyotp", data);
};
const getWalletBalanceByUserID = (userId: number, config: any) => {
    return http.get("users/" + userId + "/walletbalance", config);
};
const getGatewayStatus = (config: any) => {
    return http.get("/users/paymentgateway/status", config);
};
const createRazorpayOrder = (data: any, config: any) => {
    return http.post("/wallet/razorpay/orders", data, config);
};
const createRazorpayPayment = (data: any, config: any) => {
    return http.post("/wallet/razorpay/payments", data, config);
};
const createWalletContact = (data: any, config: any) => {
    return http.post("/wallet/contacts", data, config);
};
const payu = (data: any, config: any) => {
    return http.post("wallet/payu/payments", data, config);
};
const getPayoutContacts = (userId: number, config: any) => {
    return http.get("wallet/" + userId + "/contacts", config);
};
const payToContact = (data: any, config: any) => {
    return http.post("/wallet/payout", data, config);
};
const checkPaymentStatus = (paymentId: any, config: any) => {
    return http.get(`/billpayment/razorpay/${paymentId}/fetch-status`, config);
};
const getWalletTransactions = (userId: number, config: any, offset: any) => {
    return http.get(
        "wallet/" + userId + "/wallet-transactions?Offset=" + offset,
        config
    );
};

const getWalletTransactionsByDate = (
    userId: number,
    dates: any,
    config: any,
    offset: any
) => {
    return http.post(
        "wallet/" + userId + "/wallet-transactions?Offset=" + offset,
        dates,
        config
    );
};

const getPayoutTransactionsByDate = (
    userId: number,
    dates: any,
    config: any,
    offset: any
) => {
    return http.post(
        "wallet/" + userId + "/payout-transactions?Offset=" + offset,
        dates,
        config
    );
};
const updatePassword = (userId: number, data: any, config: any) => {
    return http.put("/users/" + userId + "/updatepassword", data, config);
};
const updatePin = (userId: number, data: any, config: any) => {
    return http.post("/wallet/" + userId + "/set-securitypin", data, config);
};
const getUserDetailsByID = (userId: number, config: any) => {
    return http.get("users/" + userId, config);
};
const updateUserDetails = (userId: number, data: any, config: any) => {
    return http.put("/users/" + userId + "/update-userdetails", data, config);
};

const createWalletPayContact = (data: any, config: any) => {
    return http.post("/wallet/wallet-contacts", data, config);
};
const searchWalletPayoutContacts = (data: any, userId: number, config: any) => {
    return http.post("wallet/" + userId + "/search-contacts", data, config);
};
const searchUserByNameOrMobile = (data: any, config: any) => {
    return http.post("/users/search-users", data, config);
};
const payToWallet = (data: any, userId: any, config: any) => {
    return http.post("/wallet/" + userId + "/transfer", data, config);
};
const createPaymentRequest = (data: any, userId: any, config: any) => {
    return http.post(
        "/wallet/" + userId + "/paymentpageid/sendsms",
        data,
        config
    );
};
const getTransactionsDetailsByID = (
    userId: number,
    transactionId: number,
    config: any
) => {
    return http.get(
        "/wallet/" + userId + "/wallet-transactions/" + transactionId,
        config
    );
};
const getCaptcha = (config: any) => {
    return http.get("/aadhar/getcaptcha", config);
};
const sendOtpKyc = (data: any, config: any) => {
    return http.post("/aadhar/getotp", data, config);
};
const sendVerifyOtp = (data: any, config: any) => {
    return http.post("/aadhar/verifyotp", data, config);
};
const sendPaymentPageID = (data: any) => {
    return http.post("/wallet/paymentpageid/orders", data);
};
const createRazorpayPaymentGlobal = (data: any) => {
    return http.post("/wallet/paymentpageid/payments", data);
};
const FetchBillerListByCategoryName = (
    config: any,
    CategoryName: any,
    support: any
) => {
    return http.get(
        "/billpayment/billerlist?categoryName=" +
            CategoryName +
            "&QuickPaySupported=" +
            support,
        config
    );
};
const FetchBillerInfoPost = (data: any, config: any) => {
    return http.post("/billpayment/billerInfo", data, config);
};
const FetchBillDetails = (data: any, config: any) => {
    return http.post("/billpayment/billfetch", data, config);
};
const paybill = (data: any, config: any) => {
    return http.post("/billpayment/billpay", data, config);
};
const TrackComplaint = (data: any, config: any) => {
    return http.post("/billpayment/track-complaint", data, config);
};
const ComplaintRegister = (data: any, config: any) => {
    return http.post("/billpayment/register-complaint", data, config);
};
const transactionStatus = (data: any, config: any) => {
    return http.post("/billpayment/transaction-status", data, config);
};
const quickpaybill = (data: any, config: any) => {
    return http.post("/billpayment/quick-billpay", data, config);
};
const searchPayout = (userId: any, data: any, config: any) => {
    return http.post("/wallet/" + userId + "/search-contacts", data, config);
};
const deletePayout = (userId: any, payoutId: any, config: any) => {
    return http.delete("wallet/" + userId + "/contacts/" + payoutId, config);
};
const downloadPayoutCsv = (userId: any, data: any, config: any) => {
    return http.post(
        "wallet/" + userId + "/payout-transactions/csv",
        data,
        config
    );
};
const downloadwalletTransactionsCsv = (userId: any, data: any, config: any) => {
    return http.post(
        "wallet/" + userId + "/wallet-transactions/csv",
        data,
        config
    );
};
const userPercentage = (userId: any, config: any) => {
    return http.get("users/" + userId + "/walletcommission-percentage", config);
};
const Commisions = (userId: any, offset: any, config: any) => {
    return http.get(
        "wallet/" + userId + "/referral-commissions?offset=" + offset,
        config
    );
};
const referUsers = (userId: any, offset: any, config: any) => {
    return http.get(
        "users/" + userId + "/referred-users/mobileNumber?offset=" + offset,
        config
    );
};
const pineLabOrders = (data: any, config: any) => {
    return http.post("wallet/pinelabs/orders", data, config);
};
const fetchOrderIdBbps = (data: any, config: any) => {
    return http.post("billpayment/razorpay/orders", data, config);
};

const LoginServices = {
    referUsers,
    Commisions,
    fetchOrderIdBbps,
    deletePayout,
    payu,
    quickpaybill,
    transactionStatus,
    ComplaintRegister,
    TrackComplaint,
    paybill,
    FetchBillDetails,
    FetchBillerInfoPost,
    loginWithEmailPassword,
    loginWithPhonePassword,
    loginWithOtp,
    searchPayout,
    getWalletBalanceByUserID,
    createRazorpayOrder,
    createRazorpayPayment,
    createRazorpayPaymentGlobal,
    createWalletContact,
    getPayoutContacts,
    payToContact,
    getWalletTransactions,
    checkPaymentStatus,
    getWalletTransactionsByDate,
    updatePassword,
    getUserDetailsByID,
    updateUserDetails,
    createWalletPayContact,
    searchWalletPayoutContacts,
    searchUserByNameOrMobile,
    payToWallet,
    updatePin,
    getTransactionsDetailsByID,
    getCaptcha,
    sendOtpKyc,
    sendVerifyOtp,
    sendPaymentPageID,
    createPaymentRequest,
    getPayoutTransactionsByDate,
    FetchBillerListByCategoryName,
    getGatewayStatus,
    downloadPayoutCsv,
    downloadwalletTransactionsCsv,
    userPercentage,
    pineLabOrders,
};

export default LoginServices;

import React from 'react';
import './App.css';

function Contact() {
  return (
  <>
  <h1>Welcome Contact Page</h1>
  </>
  );
}

export default Contact;
import React, { useState } from "react";
import { toast } from "react-toastify";
// import { Services } from "../../../services";
import resolveClasses from "./resolve.module.css";
import { useNavigate } from "react-router-dom";
function Modal(props: any) {
    const Navigate = useNavigate();

    const token = { Authorization: `token ${sessionStorage.getItem("token")}` };

    const openhandler = (e: any) => {
        e.stopPropagation();
    };
    return (
        <>
            <div className={resolveClasses["modal"]}>
                <div
                    className={resolveClasses["modal-content"]}
                    onClick={openhandler}>
                    <div className={resolveClasses["header"]}>
                        <p>Warning </p>
                    </div>
                    <p>{props.news}</p>
                    {/* <span></span> */}
                    <div className={resolveClasses["control-btns"]}>
                        <button
                            className={resolveClasses["delete-btn"]}
                            type="button"
                            onClick={() => {
                                Navigate("/dashboard");
                            }}>
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Modal;

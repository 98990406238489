import React, { useState, useEffect } from "react";
import Moment from "react-moment";
import { useNavigate } from "react-router-dom";
import DashboardNav from "./dashboardNav";
import "./dashboard.css";
import WalletBlcHeader from "./walletBlcHeader";
import LoginServices from "../../services/LoginServices";
import Razor from "./Razor";
import { Modal, Row, Col } from "react-bootstrap";
import ReactPaginate from "react-paginate";
function PayoutTransactions() {
    const navigate = useNavigate();
    const [walletBlc, setWalletBlc] = useState<any>(0);
    const [addBlc, setAddBlc] = useState<number>();
    const [walletTransactions, setWalletTransactions] = useState<any>();
    const [referralUser, setReferralUsers] = useState<any>();
    const [referralPageCount, setReferralPageCount] = useState(1);
    const [referralpagsize, setreferralpagesize] = useState(1);
    const [fileurl, setFileurl] = useState<any>();
    const userId = localStorage.getItem("userid");
    const [startDate, setStartDate] = useState<string>("");
    const [endDate, setEndDate] = useState<string>("");
    const [currentRow, setCurrentRow] = useState<any>();
    const [forcepage, setforcepage] = useState<any>();
    const [showModal, setShowModal] = useState<boolean>(false);
    const [pageCount, setPageCount] = useState(1);
    const [pagsize, setpagesize] = useState(1);
    const [tabActive, setTabactive] = useState(1);

    useEffect(() => {
        if (userId) {
            let config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            };
            LoginServices.getWalletBalanceByUserID(parseInt(userId), config)
                .then((response: any) => {
                    console.log(response);
                    if (response.data.Status == 1) {
                        setWalletBlc(response?.data?.walletBalance);
                    } else {
                    }

                    console.log(response.data);
                })
                .catch((e: Error) => {
                    console.log(e);
                });
            filterByDate(0);
            referralUsers(0);
        } else {
            alert("Login to see your dashboard");
            navigate("/");
        }
    }, [""]);
    const referralUsers = (offset: any) => {
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        if (userId) {
            LoginServices.referUsers(parseInt(userId), offset, config)
                .then((response: any) => {
                    console.log(response);
                    if (response.data.Status == 1) {
                        setReferralUsers(response?.data?.Users);
                        setreferralpagesize(response?.data?.PageSize);
                        setReferralPageCount(
                            Math.ceil(
                                Number(response?.data?.TotalRows) /
                                    Number(response?.data?.PageSize)
                            )
                        );
                    } else {
                    }

                    console.log(response.data);
                })
                .catch((e: Error) => {
                    console.log(e);
                });
        }
    };
    const filterByDate = (offset: any) => {
        console.log(offset);
        // if (new Date(startDate) > new Date(endDate)) {
        //     alert("Start date is not more than end date");
        //     return;
        // }
        const payload: any = {};
        // if (startDate.length) {
        //     payload.fromDate = startDate;
        // }
        // if (endDate.length) {
        //     payload.toDate = endDate;
        // }
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        if (userId) {
            LoginServices.Commisions(parseInt(userId), offset, config)
                .then((response: any) => {
                    console.log(response);
                    if (response.data.Status == 1) {
                        setWalletTransactions(
                            response?.data?.WalletTransactions
                        );

                        setpagesize(response?.data?.PageSize);
                        setPageCount(
                            Math.ceil(
                                Number(response?.data?.TotalRows) /
                                    Number(response?.data?.PageSize)
                            )
                        );
                    } else {
                    }

                    console.log(response.data);
                })
                .catch((e: Error) => {
                    console.log(e);
                });
        }
    };
    const getTDetails = (id: any) => {
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        setCurrentRow({});
        if (userId) {
            LoginServices.getTransactionsDetailsByID(
                parseInt(userId),
                id,
                config
            )
                .then((response: any) => {
                    console.log(response);
                    if (response.data.Status == 1) {
                        setCurrentRow(response?.data?.walletTransaction);
                    } else {
                    }

                    console.log(response.data);
                })
                .catch((e: Error) => {
                    console.log(e);
                });
        }
    };
    const handlePageChange = (selectedObject: any) => {
        filterByDate(selectedObject.selected * pagsize);
    };
    const referhandlePageChange = (selectedObject: any) => {
        referralUsers(selectedObject.selected * referralpagsize);
    };
    const downloadHandler = (e: any) => {
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        // let data = { fromDate: startDate, toDate: endDate };
        const payload: any = {};
        if (startDate.length) {
            payload.fromDate = startDate;
        }
        if (endDate.length) {
            payload.toDate = endDate;
        }
        if (userId) {
            LoginServices.downloadPayoutCsv(parseInt(userId), payload, config)
                .then((response: any) => {
                    console.log(response);
                    if (response.data.Status == 1) {
                        window.open(
                            response?.data?.FileURL,
                            "_blank",
                            "noopener,noreferrer"
                        );
                    } else {
                    }

                    console.log(response.data);
                })
                .catch((e: Error) => {
                    console.log(e);
                });
        }
    };
    return (
        <>
            <div className="main-bg">
                <div>
                    <DashboardNav active={"commissions"}></DashboardNav>
                </div>
                <div>
                    <div className="row" style={{ width: "90%" }}>
                        <div className="col-4">
                            <p className="transaction-head">Refer and Earn</p>
                        </div>
                        {/* <div className="d-flex date-export-divs col-8">
                            <div className="dates-ips">
                                <input
                                    onChange={(e) =>
                                        setStartDate(e.target.value)
                                    }
                                    className="date-ips"
                                    type="Date"
                                />
                                <input
                                    onChange={(e) => setEndDate(e.target.value)}
                                    className="date-ips"
                                    type="Date"
                                />
                                <button
                                    onClick={() => {
                                        if (
                                            startDate?.length > 0 &&
                                            endDate?.length > 0
                                        ) {
                                            setforcepage(0);
                                            filterByDate(0);
                                        } else if (startDate?.length === 0) {
                                            alert("From Date is required");
                                        } else if (endDate?.length === 0) {
                                            alert("To Date is required");
                                        }
                                    }}
                                    className="go-btn">
                                    Go
                                </button>
                            </div>
                            <div
                                className="export-div"
                                onClick={downloadHandler}>
                                <img
                                    className="dscsdc"
                                    src="assets/img/down-arrow.png"
                                    alt=""
                                />
                            </div>
                        </div> */}
                        <div className="refertabs">
                            <button
                                className={
                                    tabActive == 1 ? "tabactive" : "tabinactive"
                                }
                                onClick={() => {
                                    setTabactive(1);
                                }}>
                                Referral Users
                            </button>
                            <button
                                className={
                                    tabActive == 2 ? "tabactive" : "tabinactive"
                                }
                                onClick={() => {
                                    setTabactive(2);
                                }}>
                                Referral Commissions
                            </button>
                            <button
                                className={
                                    tabActive == 3 ? "tabactive" : "tabinactive"
                                }
                                onClick={() => {
                                    setTabactive(3);
                                }}>
                                Share
                            </button>
                        </div>
                    </div>
                    {tabActive == 1 && (
                        <div className="total-transaction-box-main table-responsive">
                            {referralUser?.length != 0 && (
                                <table className="table table-bordered">
                                    <thead>
                                        <tr className="trans-row">
                                            <th>User ID</th>
                                            <th>First Name</th>
                                            <th>Last Name</th>
                                            <th>Wallet Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody style={{ borderTopWidth: 0 }}>
                                        {referralUser?.length != 0 &&
                                            referralUser?.map((item: any) => {
                                                return (
                                                    <tr
                                                        className="trans-row1"
                                                        key={item}>
                                                        <td>
                                                            {" "}
                                                            {item?.userId}{" "}
                                                        </td>
                                                        <td>
                                                            {" "}
                                                            {
                                                                item?.firstName
                                                            }{" "}
                                                        </td>

                                                        <td>
                                                            {" "}
                                                            {
                                                                item?.lastName
                                                            }{" "}
                                                        </td>

                                                        <td>
                                                            ₹
                                                            {item?.walletAmount}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                    </tbody>
                                </table>
                            )}
                            {referralUsers?.length > 0 && (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                    }}>
                                    <ReactPaginate
                                        pageCount={referralPageCount}
                                        // pageRange={2}
                                        marginPagesDisplayed={2}
                                        onPageChange={referhandlePageChange}
                                        breakClassName={"page-item"}
                                        breakLinkClassName={"page-link"}
                                        containerClassName={"pagination"}
                                        pageClassName={"page-item"}
                                        pageLinkClassName={"page-link"}
                                        previousClassName={"page-item"}
                                        previousLinkClassName={"page-link"}
                                        nextClassName={"page-item"}
                                        nextLinkClassName={"page-link"}
                                        activeClassName={"active"}
                                    />
                                </div>
                            )}
                            {walletTransactions?.length == 0 && (
                                <h3>No Records Found</h3>
                            )}
                        </div>
                    )}
                    {tabActive == 2 && (
                        <div className="total-transaction-box-main table-responsive">
                            {walletTransactions?.length != 0 && (
                                <table className="table table-bordered">
                                    <thead>
                                        <tr className="trans-row">
                                            <th>User ID</th>
                                            <th>Name</th>
                                            <th>wallet_transactionId</th>
                                            <th>razorpayTransactionId</th>
                                            <th>Date & Time</th>
                                            <th>Balance</th>
                                        </tr>
                                    </thead>
                                    <tbody style={{ borderTopWidth: 0 }}>
                                        {walletTransactions?.length != 0 &&
                                            walletTransactions?.map(
                                                (item: any) => {
                                                    return (
                                                        <tr
                                                            className="trans-row1"
                                                            key={item}>
                                                            <td>
                                                                {" "}
                                                                {
                                                                    item?.userId
                                                                }{" "}
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                {
                                                                    item?.firstName
                                                                }{" "}
                                                            </td>

                                                            <td>
                                                                {" "}
                                                                {
                                                                    item?.wallet_transactionId
                                                                }{" "}
                                                            </td>

                                                            <td>
                                                                {" "}
                                                                {
                                                                    item?.razorpay_TransactionId
                                                                }{" "}
                                                            </td>
                                                            <td>
                                                                <Moment
                                                                    format="DD MMM YYYY hh:mm A"
                                                                    subtract={{
                                                                        hours: 5,
                                                                        minutes: 30,
                                                                    }}>
                                                                    {
                                                                        item?.transactionDateTime
                                                                    }
                                                                </Moment>
                                                            </td>

                                                            <td>
                                                                ₹
                                                                {
                                                                    item?.after_balance
                                                                }
                                                            </td>
                                                        </tr>
                                                    );
                                                }
                                            )}
                                    </tbody>
                                </table>
                            )}
                            {walletTransactions?.length > 0 && (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                    }}>
                                    <ReactPaginate
                                        pageCount={pageCount}
                                        // pageRange={2}
                                        forcePage={forcepage}
                                        marginPagesDisplayed={2}
                                        onPageChange={handlePageChange}
                                        breakClassName={"page-item"}
                                        breakLinkClassName={"page-link"}
                                        containerClassName={"pagination"}
                                        pageClassName={"page-item"}
                                        pageLinkClassName={"page-link"}
                                        previousClassName={"page-item"}
                                        previousLinkClassName={"page-link"}
                                        nextClassName={"page-item"}
                                        nextLinkClassName={"page-link"}
                                        activeClassName={"active"}
                                    />
                                </div>
                            )}
                            {walletTransactions?.length == 0 && (
                                <h3>No Records Found</h3>
                            )}
                        </div>
                    )}

                    <Modal
                        show={showModal}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        // dialogClassName="c-modal-pay-bills"
                        onHide={() => setShowModal(false)}>
                        {/* <Modal.Header closeButton>
        
      </Modal.Header> */}
                        <Modal.Body>
                            <div className="t-details-div">
                                <table className="table table-striped">
                                    <tbody>
                                        <tr>
                                            <td> Name </td>
                                            <td>:</td>
                                            <td>
                                                {" "}
                                                {
                                                    currentRow?.bankAccountName
                                                }{" "}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td> Mobile Number </td>
                                            <td>:</td>
                                            <td>
                                                {" "}
                                                {currentRow?.mobileNumber}{" "}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td> Bank Account Number </td>
                                            <td>:</td>
                                            <td>
                                                {" "}
                                                {
                                                    currentRow?.bankAccountNumber
                                                }{" "}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td> Bank IFSC Code </td>
                                            <td>:</td>
                                            <td>
                                                {" "}
                                                {currentRow?.bankIFSCCode}{" "}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td> Order ID </td>
                                            <td>:</td>
                                            <td>
                                                {" "}
                                                {
                                                    currentRow?.wallet_transactionId
                                                }{" "}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td> Sub total </td>
                                            <td>:</td>
                                            <td>
                                                {" "}
                                                ₹{
                                                    currentRow?.subtotalAmount
                                                }{" "}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td> Charges </td>
                                            <td>:</td>
                                            <td> ₹{currentRow?.charges} </td>
                                        </tr>
                                        <tr>
                                            <td> GST Amount </td>
                                            <td>:</td>
                                            <td> ₹{currentRow?.GSTAmount} </td>
                                        </tr>

                                        <tr>
                                            <td> Amount </td>
                                            <td>:</td>
                                            <td> ₹{currentRow?.amount} </td>
                                        </tr>
                                        <tr>
                                            <td> Transaction Type </td>
                                            <td>:</td>
                                            <td>
                                                {currentRow?.transactionType ==
                                                    1 && <span>Recharge</span>}
                                                {currentRow?.transactionType ==
                                                    2 && <span>Payout</span>}
                                                {currentRow?.transactionType ==
                                                    3 && (
                                                    <span>Wallet Debit</span>
                                                )}
                                                {currentRow?.transactionType ==
                                                    4 && (
                                                    <span>Wallet Credit</span>
                                                )}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td> Date </td>
                                            <td>:</td>
                                            <td>
                                                <Moment format="DD MMM YYYY hh:mm A">
                                                    {
                                                        currentRow?.transactionDateTime
                                                    }
                                                </Moment>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td> Transactions Status </td>
                                            <td>:</td>
                                            <td> Completed </td>
                                        </tr>
                                        <tr>
                                            <td> Description </td>
                                            <td>:</td>
                                            <td> {currentRow?.description} </td>
                                        </tr>
                                        <tr>
                                            <td> Notes </td>
                                            <td>:</td>
                                            <td> {currentRow?.notes} </td>
                                        </tr>
                                        <tr>
                                            <td> Payout ContactId </td>
                                            <td>:</td>
                                            <td>
                                                {" "}
                                                {
                                                    currentRow?.payout_contactId
                                                }{" "}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td> Razorpay TransactionId </td>
                                            <td>:</td>
                                            <td>
                                                {" "}
                                                {
                                                    currentRow?.razorpay_TransactionId
                                                }{" "}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            </div>
        </>
    );
}

export default PayoutTransactions;

import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import DashboardNav from "./dashboardNav";
import "./dashboard.css";
import WalletBlcHeader from "./walletBlcHeader";
import LoginServices from "../../services/LoginServices";
import { css } from "@emotion/react";
import { PulseLoader } from "react-spinners";
import BillpaymentNav from "./billpaymentNav";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BillpaymentComplaintSubmenu from "./BillpaymentComplaintSubmenu";
const override = css`
    display: block;
    margin: 0 auto;
    border-color: "#010080";
`;

function KycPending() {
    let [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [refreshP, setRefreshP] = useState<any>("");
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();

    const [walletBlc, setWalletBlc] = useState<any>(0);
    const [status, setStatus] = useState<any>();
    const [trandate, settransdate] = useState<any>();
    const stat = sessionStorage.getItem("status");
    const userId = localStorage.getItem("userid");

    useEffect(() => {
        let transdate;
        if (stat) {
            setStatus(JSON.parse(stat));
            transdate = new Date(JSON.parse(stat)?.txnList?.txnDate);

            const date = `${String(transdate.getDate()).padStart(
                2,
                "0"
            )}-${String(transdate.getMonth()).padStart(
                2,
                "0"
            )}-${transdate.getFullYear()}`;
            settransdate(date);
        }
    }, []);
    useEffect(() => {
        if (userId) {
            let config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            };
            LoginServices.getWalletBalanceByUserID(parseInt(userId), config)
                .then((response: any) => {
                    console.log(response);
                    if (response.data.Status == 1) {
                        setWalletBlc(response?.data?.walletBalance);
                    } else {
                        alert(response?.data?.Message);
                    }

                    console.log(response.data);
                })
                .catch((e: Error) => {
                    console.log(e);
                });
        } else {
            alert("Login to see your dashboard");
            navigate("/");
        }
    }, [refreshP == "yes"]);
    // showSection == 'biller-list'

    return (
        <>
            <div className="main-bg">
                <div>
                    <DashboardNav active={"bill-payments"}></DashboardNav>
                </div>
                <div className="paymentWrapper">
                    <div className="payment-header">
                        <h2>Complaint</h2>
                        <img
                            className="payment-header-icon"
                            src="assets/billpayment/billpayment_header.png"
                            alt=""
                        />
                    </div>
                </div>

                <div className="Complaint-wrapper">
                    <div className="Complaint-Nav">
                        <BillpaymentNav active="complaints" />
                    </div>
                    <div className="Complaint-submenuWrapper">
                        <BillpaymentComplaintSubmenu active={"search"} />
                        <div
                            className="Complaint-status"
                            style={{
                                width: "80%",
                                justifyItems: "left",
                            }}>
                            <h1 style={{ justifySelf: "center" }}>
                                Transaction Details{" "}
                            </h1>
                            <div className="Complaint-status-items">
                                <div className="Complaint-status-item">
                                    <h3>Agent ID</h3>
                                    <p>:</p>
                                    <h5>{status?.txnList?.agentId}</h5>
                                </div>
                                <div className="Complaint-status-item">
                                    <h3>Amount</h3>
                                    <p>:</p>
                                    <h5>
                                        {Number(status?.txnList?.amount) / 100}
                                    </h5>
                                </div>
                                <div className="Complaint-status-item">
                                    <h3>Biller Name</h3>
                                    <p>:</p>
                                    <h5>{status?.txnList?.billerName}</h5>
                                    {/* <h5></h5> */}
                                </div>
                                <div className="Complaint-status-item">
                                    <h3>Txn Reference Id</h3>
                                    <p>:</p>
                                    <h5>{status?.txnList?.txnReferenceId}</h5>
                                </div>
                                <div className="Complaint-status-item">
                                    <h3>Txn Date</h3>
                                    <p>:</p>
                                    <h5>{trandate}</h5>
                                </div>
                                <div className="Complaint-status-item">
                                    <h3>Txn Status</h3>
                                    <p>:</p>
                                    <h5>{status?.txnList?.txnStatus}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <ToastContainer
                        position="top-center"
                        autoClose={5000}
                        hideProgressBar
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </div>
            </div>
        </>
    );
}

export default KycPending;

import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import DashboardNav from './dashboardNav';
import './dashboard.css';
import WalletBlcHeader from './walletBlcHeader';
import LoginServices from '../../services/LoginServices';
import { css } from "@emotion/react";
import { PulseLoader } from 'react-spinners';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: '#010080';
`;

function PaymentLinks() {
    let [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [refreshP, setRefreshP] = useState<any>('');
    const { register, formState: { errors }, handleSubmit, reset } = useForm();


    const onSubmit = (data: any) => {


        console.log(data);
        const payload: any = {
            mobileNumber: data.mobileNumber,
            amount: data.amount
        }
   
        let config = {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        };
        setLoading(true);

        LoginServices.createPaymentRequest(payload, userId, config)
            .then((response: any) => {
                setLoading(false);
                console.log(response);
                if (response.data.Status == 1) {
                    alert(response.data.Message);
                    window.location.reload()
                }
                else {
                    alert(response?.data?.Message)
                }

                console.log(response.data);
            })
            .catch((e: any) => {
                setLoading(false);
                console.log(e);
                alert(e?.response.data?.Message)
            });

    }
    const [walletBlc, setWalletBlc] = useState<any>(0);


    const userId = localStorage.getItem('userid');


    useEffect(() => {
        if (userId) {

            let config = {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            };
            LoginServices.getWalletBalanceByUserID(parseInt(userId), config)
                .then((response: any) => {
                    console.log(response);
                    if (response.data.Status == 1) {
                        setWalletBlc(response?.data?.walletBalance)
                    }
                    else {
                        alert(response?.data?.Message)
                    }

                    console.log(response.data);
                })
                .catch((e: Error) => {
                    console.log(e);

                });

        } else {
            alert('Login to see your dashboard')
            navigate('/')
        }

    }, [refreshP == 'yes']);
    // showSection == 'biller-list'


    return (
        <>

            <div className="main-bg">
                <div>
                    <DashboardNav active={"payment-links"}></DashboardNav>
                </div>
                <div>


                    <div>
                        <div>
                            <p className="pay-bills-head" style={{ marginLeft: '50px' }}>Payment Links</p>

                        </div>

                        <div className="form-main">
                            <form className="form-sub" onSubmit={handleSubmit(onSubmit)}>

                                <input maxLength={10} {...register("mobileNumber", { required: true, pattern: /^([0-9]{10})+$/i })}
                                    name="mobileNumber" id="mobileNumber" className="pay-bill-ip" type="text" placeholder="Enter Mobile Number" />
                                <label className="error-text">{errors?.mobileNumber?.type == 'required' && "Mobile number is required "} {errors?.mobileNumber?.type == 'pattern' && "Mobile Number Invalid"} </label>

                                <input maxLength={6} {...register("amount", { required: true, pattern: /^([0-9])+$/i })}
                                    name="amount" id="amount" className="pay-bill-ip" type="text" placeholder="Enter Amount" />
                                <label className="error-text">{errors?.amount?.type == 'required' && "Amount is required "} {errors?.amount?.type == 'pattern' && "Amount Invalid"} </label>


                                <PulseLoader color={'#010080'} loading={loading} css={override} size={20} />

                                <div className="d-flex" style={{ marginTop: '20px' }}>
                                    <button type='submit' className="add-biller-btn">Send Request</button>
                                </div>

                            </form>
                        </div>

                    </div>



                    <ToastContainer
                        position="top-center"
                        autoClose={5000}
                        hideProgressBar
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />

                </div>
            </div>
        </>
    );
}

export default PaymentLinks;
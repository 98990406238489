import http from "../http-common";
import { SignupTypes, SignupVerifyOTPRequest,SignupUser } from "../types/Signuptypes";

const get = (id: any) => {
  return http.get<SignupTypes>(`/xxxx/${id}`);
};

const create = (data: SignupTypes) => {
  return http.post<SignupTypes>("/users/signup/sendotp", data);
};

const verifyOTP = (data: SignupVerifyOTPRequest) => {
  return http.post<any>("/users/signup/verifyotp", data);
};

const RegisterUser = (data: any) => {
  return http.post<any>("/users/signup", data);
};


const SignupServices = {
  get,
  create,
  verifyOTP,
  RegisterUser
};

export default SignupServices;
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import DashboardNav from "./dashboardNav";
import "./dashboard.css";
import WalletBlcHeader from "./walletBlcHeader";
import LoginServices from "../../services/LoginServices";
import { css } from "@emotion/react";
import { PulseLoader } from "react-spinners";
import BillpaymentNav from "./billpaymentNav";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaUserCircle } from "react-icons/fa";
const override = css`
    display: block;
    margin: 0 auto;
    border-color: "#010080";
`;

function KycPending() {
    let [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [refreshP, setRefreshP] = useState<any>("");

    const [dropDown, setDropDown] = useState<any>();

    const [walletBlc, setWalletBlc] = useState<any>(0);

    const userId = localStorage.getItem("userid");

    useEffect(() => {
        if (userId) {
            let config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            };
            LoginServices.getWalletBalanceByUserID(parseInt(userId), config)
                .then((response: any) => {
                    console.log(response);
                    if (response.data.Status == 1) {
                        setWalletBlc(response?.data?.walletBalance);
                    } else {
                        alert(response?.data?.Message);
                    }

                    console.log(response.data);
                })
                .catch((e: Error) => {
                    console.log(e);
                });
        } else {
            alert("Login to see your dashboard");
            navigate("/");
        }
    }, [refreshP == "yes"]);
    // showSection == 'biller-list'

    return (
        <>
            <div className="main-bg">
                <div>
                    <DashboardNav active={"bill-payments"}></DashboardNav>
                </div>
                <div style={{ backgroundColor: "#f6f7ff" }}>
                    <div className="paymentWrapper">
                        <div className="payment-header">
                            <div className="header-wrapper">
                                <h2>Pay Bills</h2>
                                <img
                                    style={{ alignSelf: "center" }}
                                    src="/assets/img/arrow.svg"
                                    alt=""
                                />
                                <h3>Broadband Postpaid</h3>
                            </div>
                            <img
                                className="payment-header-icon"
                                src="assets/billpayment/billpayment_header.png"
                                alt=""
                            />
                        </div>
                    </div>
                    <div className="biller-details-wrapper">
                        <div className="biller-details">
                            <div className="biller-detail">
                                <FaUserCircle size={35} className="icon" />
                                <select
                                    className="select"
                                    defaultValue={"biller-type"}>
                                    <option value={"biller-type"}>
                                        Select Biller
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <ToastContainer
                        position="top-center"
                        autoClose={5000}
                        hideProgressBar
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </div>
            </div>
        </>
    );
}

export default KycPending;

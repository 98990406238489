import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import DashboardNav from "./dashboardNav";
import "./dashboard.css";
import WalletBlcHeader from "./walletBlcHeader";
import LoginServices from "../../services/LoginServices";
import { css } from "@emotion/react";
import { PulseLoader } from "react-spinners";
import BillpaymentNav from "./billpaymentNav";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaUserCircle } from "react-icons/fa";
import Spinner from "../spinner";
import Billpayment from "./billpaymentNav";
const override = css`
    display: block;
    margin: 0 auto;
    border-color: "#010080";
`;

function KycPending() {
    const { type } = useParams();
    const [billername, setbillername] = useState<any>(
        sessionStorage.getItem("Biller")
    );

    let bill = sessionStorage.getItem("billdetails");
    const [spinner, setSpinner] = useState(false);
    let [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [refreshP, setRefreshP] = useState<any>("");
    const [billdetails, setbilldetails] = useState<any>();
    const [walletBlc, setWalletBlc] = useState<any>(0);
    const [billern] = useState<any>(localStorage.getItem("billerName"));
    const [usernumber] = useState<any>(sessionStorage.getItem("usernumber"));
    const [billercategory] = useState<any>(sessionStorage.getItem("BillerCat"));
    const [amount, setamount] = useState<any>();
    const userId = localStorage.getItem("userid");
    useEffect(() => {
        setamount(Number(sessionStorage.getItem("amount")) / 100);
        if (bill) {
            setbilldetails(JSON.parse(bill));
            console.log(JSON.parse(bill));
        }
    }, []);
    useEffect(() => {
        if (userId) {
            let config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            };
            LoginServices.getWalletBalanceByUserID(parseInt(userId), config)
                .then((response: any) => {
                    console.log(response);
                    if (response.data.Status == 1) {
                        setWalletBlc(response?.data?.walletBalance);
                    } else {
                        alert(response?.data?.Message);
                    }

                    console.log(response.data);
                })
                .catch((e: Error) => {
                    console.log(e);
                });
        } else {
            alert("Login to see your dashboard");
            navigate("/");
        }
    }, [refreshP == "yes"]);
    // showSection == 'biller-list'
    const amountChange = (e: any) => {
        setamount(e.target.value);
    };
    const payHandler = () => {
        setSpinner(true);
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        const billerAdhoc = localStorage.getItem("billerAdhoc") == "false";
        let id = {};
        if (billerAdhoc) {
            id = {
                RequestID: billdetails?.RequestID,
            };
        }
        const data = {
            billerId: sessionStorage.getItem("Biller"),
            billerAdhoc: localStorage.getItem("billerAdhoc"),
            ...id,
            mobileNumber: sessionStorage.getItem("usernumber"),
            Params: {
                inputParams: {
                    input: [...billdetails?.BillDetails?.inputParams.input],
                },
                billerResponse: { ...billdetails?.BillDetails?.billerResponse },
                additionalInfo: {
                    info: [...billdetails?.BillDetails?.additionalInfo?.info],
                },
                paymentMethod: {
                    paymentMode: "NEFT",
                    quickPay: "Y",
                    splitPay: "N",
                },
                amountInfo: {
                    amount: Number(amount) * 100,
                    currency: "356",
                    custConvFee: "0",
                },
            },
        };
        LoginServices.quickpaybill(data, config)
            .then((response: any) => {
                setSpinner(false);
                if (response.data?.Status == 1) {
                    sessionStorage.setItem(
                        "success",
                        JSON.stringify(response?.data?.BillPayResponse)
                    );
                    navigate(
                        `/dashboard/bill-payments/quick-pay/QuickPaySuccess`
                    );
                }
                console.log(response, "1111");
            })
            .catch((err) => {
                setSpinner(false);
                alert("some thing went wrong try after sometime");
            });
    };
    return (
        <>
            {spinner && <Spinner />}
            <div className="main-bg">
                <div>
                    <DashboardNav active={"bill-payments"}></DashboardNav>
                </div>
                <div style={{ backgroundColor: "#f6f7ff" }}>
                    <div className="paymentWrapper">
                        <div className="payment-header">
                            <div className="header-wrapper">
                                <h2>Pay Bills</h2>
                            </div>
                            <img
                                className="payment-header-icon"
                                src="assets/billpayment/billpayment_header.png"
                                alt=""
                            />
                        </div>
                    </div>
                    <div className="payment-wrapper">
                        <Billpayment active="QuickPay" />
                    </div>
                    <div className="quickpay-amount-wrapper ">
                        <div className="quickpay-amount amount-wrapper">
                            <div className="amount-data">
                                <label htmlFor="BillerCategory">
                                    Biller Category
                                </label>
                                <input
                                    type="text"
                                    placeholder={billercategory}
                                    disabled
                                />
                            </div>
                            <div className="amount-data">
                                <label htmlFor="BillerName">Biller Name</label>
                                <input
                                    type="text"
                                    placeholder={billern}
                                    disabled
                                />
                            </div>
                            <div className="amount-data">
                                <label htmlFor="customerName">
                                    Customer Name
                                </label>
                                <input
                                    type="text"
                                    placeholder={
                                        billdetails?.BillDetails?.billerResponse
                                            ?.customerName
                                    }
                                    disabled
                                />
                            </div>
                            <div className="amount-data">
                                <label htmlFor="customerName">
                                    Mobile Number
                                </label>
                                <input
                                    type="text"
                                    placeholder={usernumber}
                                    disabled
                                />
                            </div>
                            {billdetails?.BillDetails?.additionalInfo?.info?.map(
                                (item: any) => {
                                    return (
                                        <div className="amount-data">
                                            <label htmlFor={item?.infoName}>
                                                {item?.infoName}
                                            </label>
                                            <input
                                                type="text"
                                                placeholder={item?.infoValue}
                                                disabled
                                            />
                                        </div>
                                    );
                                }
                            )}
                            {billdetails?.BillDetails?.inputParams?.input?.map(
                                (item: any) => {
                                    return (
                                        <div className="amount-data">
                                            <label
                                                htmlFor={
                                                    item?.input?.paramName
                                                }>
                                                {item?.paramName}
                                            </label>
                                            <input
                                                type="text"
                                                placeholder={item?.paramValue}
                                                disabled
                                            />
                                        </div>
                                    );
                                }
                            )}
                            <div className="amount-data">
                                <label htmlFor="due date">Due Date</label>
                                <input
                                    type="text"
                                    placeholder={
                                        billdetails?.BillDetails?.billerResponse
                                            ?.dueDate
                                    }
                                    disabled
                                />
                            </div>
                            <div className="amount-data">
                                <label htmlFor="billAmount">bill Amount</label>
                                <input
                                    type="number"
                                    value={amount}
                                    onChange={amountChange}
                                />
                            </div>

                            <button onClick={payHandler}>Pay</button>
                        </div>
                    </div>
                </div>
                <div>
                    <ToastContainer
                        position="top-center"
                        autoClose={5000}
                        hideProgressBar
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </div>
            </div>
        </>
    );
}

export default KycPending;

import React from "react";
import "./App.css";

function PrivacyPolicy() {
    return (
        <div className="terms-main">
            <h1> Privacy Policy</h1>
            <div>
                Our privacy policy is subject to change at any time without
                prior notice. You are advised to review the policies
                periodically for any changes. You agree to the terms and
                conditions of this Privacy Policy given by the website. We will
                not be able to onboard you in case you are not in agreement with
                the terms and condition. By mere use of Paygen Pro, you
                expressly consent to our use and disclosure of your personal
                information in accordance with this Privacy Policy. This Privacy
                Policy is incorporated into and subject to the Terms of Use.
                Collection of Personally Identifiable Information and other
                Information: When you use our website, we collect and store your
                personal information provided by you from time to time. We do so
                to ensure that you are provided a safe, efficient, smooth and
                seamless experience. This also allows us to understand your
                needs and provide for services and features that best suit them.
                We strive to provide for customizations on our website to ensure
                that your experience is always safer and easier. This requires
                collection of personal information to the extent necessary for
                achieving this purpose and objective. You may please note that
                it is mandatory to register yourself in order to use the
                website. And once you give us your personal information, you are
                not anonymous to us. We may automatically track certain
                information about you based upon your behaviour on our website
                to the extent we deem fit. If you choose to transact on the
                portal, we collect information about your transaction behaviour.
                We collect some additional information, such as a billing
                address, details of bill payment of the transaction, location,
                etc. which may be used for providing better experience to you
                while using the portal. If you choose to provide information by
                way of messages on our message boards (as & when available)
                and/or chat rooms or any other message areas or if you prefer to
                leave feedback, we will collect that information you provide to
                us. We retain this information as necessary to resolve disputes
                in terms of transactions and otherwise, where needed, provide
                customer support and troubleshoot problems as permitted by law.
                We collect personally identifiable information (email address,
                name, phone number etc.) from you when you register with us to
                create your unique identification in terms of a Virtual Payment
                Address and/or any other unique registration identification that
                is/shall be made available to our customers. Use of Demographic
                / Profile Data / Your Information We use personal information to
                provide the services you request. We use your personal
                information to resolve disputes; troubleshoot problems; Send
                money, collect money; measure consumer interest in our products
                and services and we may use this information to keep you
                apprised on any online and offline offers, products, services,
                and updates that shall be made available for use of our
                customers. We use the information thus obtained to customize
                your experience; detect and protect us against error, fraud and
                other criminal activity; enforce our terms and conditions which
                are an integral part of the use of this website; and as
                otherwise described to you at the time of such collection. We
                identify and use your IP address to help diagnose problems with
                our server, and to administer our website. Your IP address is
                also used to help identify you and to gather broad demographic
                information. Sharing of personal information: We may disclose
                personal information if required to do so by law or in the good
                faith belief that such disclosure is reasonably necessary to
                respond to subpoenas, court orders, or other legal processes. We
                may disclose personal information to law enforcement offices
                upon such requests, third party rights owners or others in the
                good faith belief that such disclosure is reasonably necessary
                to: enforce our Terms or Privacy Policy; respond to claims that
                an advertisement, posting or other content violates the rights
                of a third party; or protect the rights, property or personal
                safety of our users or the general public. We will share some or
                all of your personal information with another business entity
                should we (or our assets) plan to merge with, or be acquired by
                that business entity, or re-organization, amalgamation,
                restructuring of business. Should such a transaction occur that
                other business entity (or the new combined entity) will be
                required to follow this privacy policy with respect to your
                personal information. Security Precautions Our website has
                stringent security measures in place to protect the loss,
                misuse, and alteration of the information under our control and
                follows the best practices in the industry in this regard.
                Whenever you change or access your account information, it is
                through secure channels. Once your information is in our
                possession we adhere to strict security guidelines, protecting
                it against unauthorized access. Your Consent By using the
                website and/ or by providing your information, you consent to
                the collection and use of the information you disclose on the
                website in accordance with this Privacy Policy, including but
                not limited to Your consent for sharing your information as per
                this privacy policy. If we decide to change our privacy policy,
                we will post those changes on this page so that you are always
                aware of what information we collect, how we use it, and under
                what circumstances we disclose it.
            </div>
        </div>
    );
}

export default PrivacyPolicy;

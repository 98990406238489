import React from "react";
import BenefitClasses from "./Benifits.module.css";
import user_1 from "../../../images/HomePage_Updated/benefits_1.png";
import user_2 from "../../../images/HomePage_Updated/benefits_2.png";
import user_3 from "../../../images/HomePage_Updated/benefits_3.png";
import { Fade } from "react-awesome-reveal";

import user_4 from "../../../images/HomePage_Updated/benefits_4.png";
function Benefits() {
    const Cards = [
        {
            header: "Lower charges",
            content:
                "We charge a transparent fee starting at 1.5% with GST for all bill payments and no hidden charges.",
            img: user_1,
        },
        {
            header: "Avoid Queues",
            content:
                "Customers would no longer need to stand in queues to pay their essential bills at biller BC points.  ",
            img: user_2,
        },
        {
            header: "Save Travel Money",
            content:
                "Users can save time and money by not having to travel to various departments. They can make all these bill payments at one stop.",
            img: user_3,
        },
        {
            header: "Best Success Rate",
            content:
                "Transaction failures occur rarely with our services. We ensure that you get the best success rate for every transaction. We are as reliable as banks themselves.",
            img: user_4,
        },
    ];
    return (
        <div className={BenefitClasses["Container"]} id="benefits">
            <Fade direction="left" triggerOnce>
                <header>
                    <h3>Benefits</h3>
                    {/* <span></span> */}
                </header>
            </Fade>
            <div className={BenefitClasses["cards"]}>
                <Fade cascade triggerOnce duration={700}>
                    {Cards.map((item, index) => (
                        <div className={BenefitClasses["Card"]} key={index}>
                            <img src={item.img} alt={item.header} />
                            <h3>{item?.header}</h3>
                            <p>{item?.content}</p>
                        </div>
                    ))}
                </Fade>
            </div>
        </div>
    );
}

export default Benefits;

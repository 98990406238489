import React from "react";
import "./dashboard.css";
import { NavLink } from "react-router-dom";
function BillpaymentComplaintSubmenu(props: any) {
    const active = props.active;
    return (
        <div className="Complaint-submenu">
            <NavLink
                className={
                    active == "raise" ? "activesubmenu" : "inactivesubmenu"
                }
                to="/dashboard/bill-payments/complaints">
                Raise Complaint
            </NavLink>
            <NavLink
                className={
                    active == "status" ? "activesubmenu" : "inactivesubmenu"
                }
                to="/dashboard/bill-payments/check-status">
                Check Complaint Status
            </NavLink>
            <NavLink
                className={
                    active == "search" ? "activesubmenu" : "inactivesubmenu"
                }
                to="/dashboard/bill-payments/search-complaint">
                Search Transaction
            </NavLink>
        </div>
    );
}

export default BillpaymentComplaintSubmenu;

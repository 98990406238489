import React, { useState, useEffect } from "react";
import "./dashboard.css";

import DashboardNav from "./dashboardNav";
import LoginServices from "../../services/LoginServices";
import Billpayment from "./billpaymentNav";
import { css } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const override = css`
    display: block;
    margin: 0 auto;
    border-color: "#010080";
`;
function BillpaymentBills() {
    const [refreshP, setRefreshP] = useState<any>("");
    const [walletBlc, setWalletBlc] = useState<any>(0);

    const userId = localStorage.getItem("userid");
    const Navigate = useNavigate();
    const BillpayHandler = (e: any) => {
        if (e.currentTarget.id == "Insurance") {
            Navigate("/dashboard/bill-payments/insurance");
            return;
        }
        Navigate(
            `/dashboard/bill-payments/paybill-fetch/${e.currentTarget.id}`
        );
    };
    useEffect(() => {
        if (userId) {
            let config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            };
            LoginServices.getWalletBalanceByUserID(parseInt(userId), config)
                .then((response: any) => {
                    console.log(response);
                    if (response.data.Status == 1) {
                        setWalletBlc(response?.data?.walletBalance);
                    } else {
                        alert(response?.data?.Message);
                    }

                    console.log(response.data);
                })
                .catch((e: Error) => {
                    console.log(e);
                });
        } else {
            alert("Login to see your dashboard");
            Navigate("/");
        }
    }, [refreshP == "yes"]);
    // showSection == 'biller-list'

    return (
        <div className="main-bg">
            <div>
                <DashboardNav active={"bill-payments"}></DashboardNav>
            </div>
            <div className="paymentWrapper">
                <div className="payment-header">
                    <h2>Insurance</h2>
                    <img
                        className="payment-header-icon"
                        src="assets/billpayment/billpayment_header.png"
                        alt=""
                    />
                </div>
            </div>

            <div>
                <div
                    style={{
                        background: "#F6F7FF",
                        padding: "1rem 0rem",
                    }}>
                    <div className="payment-wrapper">
                        {/* <Billpayment active="billpay" /> */}
                        <div className="Insurance-Wrapper">
                            <button
                                className="payment-type"
                                onClick={BillpayHandler}
                                id="Life Insurance">
                                <img
                                    className="paymentType_icon"
                                    src="assets/billpayment/icons/life.svg"
                                    alt=""
                                />
                                <h2>Life Insurance</h2>
                            </button>
                            <button
                                className="payment-type"
                                onClick={BillpayHandler}
                                id="Health Insurance">
                                <img
                                    className="paymentType_icon"
                                    src="assets/billpayment/icons/health.svg"
                                    alt=""
                                />
                                <h2>Health Insurance </h2>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </div>
        </div>
    );
}

export default BillpaymentBills;

import React from 'react';
import './App.css';

function Service() {
  return (
  <>
  <h1>Welcome Service Page</h1>
  </>
  );
}

export default Service;
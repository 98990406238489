import TopNav from "./TopNav/TopNav";
import React, { ReactNode } from "react";
import LayoutClasses from "./Layout.module.css";

interface LayoutProps {
    children: ReactNode;
}
const Layout: React.FC<LayoutProps> = ({ children }) => {
    return (
        <div className={LayoutClasses["layout-container"]}>
            <TopNav />
            {children}
        </div>
    );
};
export default Layout;

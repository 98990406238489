import React from "react";
import Layout from "./Layout/Layout";
import About from "../HomePage_updated/About/Index";
import Benefits from "./Benefits/Benefits";
import Shop from "./Shop/shop";
import Bills from "./UtilityBills/Bills";
import GetStarted from "./Getstarted/Started";
import Question from "./GotQuestion/question";
import Footer from "./Footer/Footer";
function index() {
    return (
        <Layout>
            <About />
            <Benefits />
            {/* <Shop /> */}
            <Bills />
            <GetStarted />
            <Question />
            <Footer />
        </Layout>
    );
}

export default index;

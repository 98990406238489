import QuestionClasses from "./question.module.css";
import { useForm, SubmitHandler } from "react-hook-form";

type Inputs = {
    name: string;
    email: string;
    phone: string;
};

function Question() {
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm<Inputs>();
    const onSubmit: SubmitHandler<Inputs> = (data) => console.log(data);
    return (
        <div className={QuestionClasses["Container"]} id="contact">
            <h3>Still Got a Question</h3>
            <p>Write to us for more information</p>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className={QuestionClasses["form-control"]}>
                    <label htmlFor="Name">
                        Name <span>*</span>
                    </label>
                    <div>
                        <input
                            type="text"
                            {...register("name", {
                                required: "This is required",
                            })}
                        />
                        {errors?.name && <p>{errors?.name?.message}</p>}
                    </div>
                </div>
                <div className={QuestionClasses["form-control"]}>
                    <label htmlFor="Name">
                        Phone Number <span>*</span>
                    </label>
                    <div>
                        <input
                            type="number"
                            {...register("phone", {
                                required: "This is required",
                            })}
                        />

                        {errors?.phone && <p>{errors?.phone?.message}</p>}
                    </div>
                </div>
                <div className={QuestionClasses["form-control"]}>
                    <label htmlFor="Name">
                        Email <span>*</span>
                    </label>
                    <div>
                        <input
                            type="email"
                            {...register("email", {
                                required: "This is required",
                            })}
                        />

                        {errors?.email && <p>{errors?.email?.message}</p>}
                    </div>
                </div>
                <button>Submit</button>
            </form>
        </div>
    );
}

export default Question;

import StartedClasses from "./Started.module.css";
import { IoIosArrowRoundForward } from "react-icons/io";

function Started() {
    return (
        <div className={StartedClasses["Container"]}>
            <p>Ready to Get Started</p>
            <h3>Pay Utility Bills Instantly</h3>
            <button>
                Get Started <IoIosArrowRoundForward />
            </button>
        </div>
    );
}

export default Started;

import React, { useState, useEffect } from "react";
import Moment from "react-moment";
import { useNavigate } from "react-router-dom";
import DashboardNav from "./dashboardNav";
import "./dashboard.css";
import WalletBlcHeader from "./walletBlcHeader";
import LoginServices from "../../services/LoginServices";
import Razor from "./Razor";

function Profile() {
    const navigate = useNavigate();
    const [walletBlc, setWalletBlc] = useState<any>(0);
    const [addBlc, setAddBlc] = useState<number>();
    const [uEmail, setUEmail] = useState<string>("");
    const [cPassword, setCPassword] = useState<string>("");
    const [ccPin, setCCPin] = useState<string>("");
    const [cPin, setCPin] = useState<string>("");
    const [ccPassword, setCCPassword] = useState<string>("");
    const [walletTransactions, setWalletTransactions] = useState<any>();
    const userId: any = localStorage.getItem("userid");
    const [isProfile, setIsProfile] = useState<any>(1);

    const [userDetails, setUserDetails] = useState<any>();

    useEffect(() => {
        if (userId) {
            let config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            };
            LoginServices.getUserDetailsByID(parseInt(userId), config)
                .then((response: any) => {
                    console.log(response);
                    if (response.data.Status == 1) {
                        // setWalletBlc(response?.data?.walletBalance)
                        setUserDetails(response?.data?.UserDetails);
                        setUEmail(response?.data?.UserDetails?.emailId);
                    } else {
                    }

                    console.log(response.data);
                })
                .catch((e: Error) => {
                    console.log(e);
                });
            LoginServices.getWalletBalanceByUserID(parseInt(userId), config)
                .then((response: any) => {
                    console.log(response);
                    if (response.data.Status == 1) {
                        setWalletBlc(response?.data?.walletBalance);
                    } else {
                    }

                    console.log(response.data);
                })
                .catch((e: Error) => {
                    console.log(e);
                });
            LoginServices.getWalletTransactions(parseInt(userId), config, 0)
                .then((response: any) => {
                    console.log(response);
                    if (response.data.Status == 1) {
                        setWalletTransactions(
                            response?.data?.WalletTransactions
                        );
                        // setWalletBlc(response?.data?.walletBalance)
                    } else {
                    }

                    console.log(response.data);
                })
                .catch((e: Error) => {
                    console.log(e);
                });
        } else {
            alert("Login to see your dashboard");
            navigate("/");
        }
    }, [""]);
    let changePassword = () => {
        if (cPassword.length < 8) {
            alert("Password should be minimum 8 chars");
            return;
        }
        if (cPassword !== ccPassword) {
            alert("Password not matching");
            return;
        }
        const payload = {
            password: cPassword,
        };
        console.log(payload);
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        LoginServices.updatePassword(parseInt(userId), payload, config)
            .then((response: any) => {
                console.log(response);
                if (response.data.Status == 1) {
                    window.location.reload();
                } else {
                    alert(response.data.Message);
                }

                console.log(response.data);
            })
            .catch((e: Error) => {
                console.log(e);
            });
    };
    let changePin = () => {
        if (cPin.length < 4) {
            alert("Password should be minimum 4 chars");
            return;
        }
        if (cPin !== ccPin) {
            alert("Password not matching");
            return;
        }
        const payload = {
            PIN: cPin,
        };
        console.log(payload);
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        LoginServices.updatePin(parseInt(userId), payload, config)
            .then((response: any) => {
                console.log(response);
                if (response.data.Status == 1) {
                    alert(response.data.Message);
                    window.location.reload();
                } else {
                    alert(response.data.Message);
                }

                console.log(response.data);
            })
            .catch((e: Error) => {
                console.log(e);
            });
    };

    let updateUser = () => {
        const payload = {
            emailId: uEmail,
        };
        console.log(payload);
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        LoginServices.updateUserDetails(parseInt(userId), payload, config)
            .then((response: any) => {
                console.log(response);
                if (response.data.Status == 1) {
                    window.location.reload();
                } else {
                    alert(response.data.Message);
                }

                console.log(response.data);
            })
            .catch((e: Error) => {
                console.log(e);
            });
    };

    return (
        <>
            <div>
                <div>
                    <DashboardNav active={"transactions"}></DashboardNav>
                </div>
                <div>
                    <div className="account-box-main">
                        <div className="account-box-main1">
                            <div className="profile-changep-div">
                                <p className="account-head">Account</p>
                                <button
                                    onClick={() => setIsProfile(1)}
                                    className={
                                        isProfile == 1
                                            ? "profile-password-selected"
                                            : "profile-password-not-selected"
                                    }>
                                    Profile
                                </button>
                                {/* <button onClick={() => setIsProfile(2)} className={isProfile == 2 ? 'profile-password-selected' : 'profile-password-not-selected'}>Change Password</button>
                                <button onClick={() => setIsProfile(3)} className={isProfile == 3 ? 'profile-password-selected' : 'profile-password-not-selected'}>Change Pin</button> */}
                            </div>
                        </div>
                        {isProfile == 1 && (
                            <div className="account-box-main2">
                                <div className="profile-details">
                                    <div className="user-avatar-div">
                                        <div>
                                            <img
                                                src={
                                                    userDetails?.profilePic ==
                                                    "NA"
                                                        ? "assets/img/user-avatar1.png"
                                                        : userDetails?.profilePic
                                                }
                                                alt=""
                                            />
                                            <p className="user-name-account">
                                                {userDetails?.firstName}
                                            </p>
                                            {/* <p className="user-ref-account">
                                                Referral Code :{" "}
                                                {userDetails?.myReferralCode}
                                            </p> */}
                                        </div>
                                    </div>
                                    <div className={"profile-container"}>
                                        <div
                                            className={
                                                "profile-details-wrapper"
                                            }>
                                            {/* <img className="ip-email-p" src="assets/img/email.png" alt="" /> */}

                                            {/* <input
                                                onChange={(e: any) =>
                                                    setUEmail(e.target.value)
                                                }
                                                value={uEmail}
                                                disabled
                                                className="email-ip-p"
                                                type="mail"
                                                placeholder="Enter Email"
                                            /> */}

                                            <div className="profile-detailss">
                                                <strong> Referral </strong>
                                                <h6>:</h6>
                                                <p>
                                                    {
                                                        userDetails?.myReferralCode
                                                    }
                                                </p>
                                            </div>
                                            <div className="profile-detailss">
                                                <strong> Aadhar </strong>
                                                <h6>:</h6>
                                                <p>
                                                    {userDetails?.aadharNumber}
                                                </p>
                                            </div>
                                            <div className="profile-detailss">
                                                <strong> Address</strong>
                                                <h6>:</h6>
                                                <p>{userDetails?.address}</p>
                                            </div>
                                            <div className="profile-detailss">
                                                <strong> Email</strong>
                                                <h6>:</h6>
                                                <p>{uEmail}</p>
                                            </div>
                                            <div className="profile-detailss">
                                                <strong> Phone</strong>
                                                <h6>:</h6>
                                                <p>
                                                    {" "}
                                                    {userDetails?.mobileNumber}
                                                </p>
                                            </div>
                                        </div>
                                        {/* <div
                                            className="ip-inr-main"
                                            style={{ marginTop: "10px" }}>
                                            <input
                                                value={
                                                    userDetails?.mobileNumber
                                                }
                                                disabled
                                                className="email-ip-p"
                                                type="text"
                                                placeholder="Enter Mobile Number"
                                            />
                                        </div> */}
                                        {/* <div
                                            className="d-flex"
                                            style={{ marginTop: "20px" }}>
                                            <button
                                                onClick={updateUser}
                                                className="add-biller-btn">
                                                Save
                                            </button>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        )}
                        {isProfile == 2 && (
                            <div className="account-box-main3">
                                <div className="profile-details">
                                    <div className="user-details-ips">
                                        <div className="ip-inr-main">
                                            {/* <img className="ip-password-img" src="assets/img/lock.png" alt="" /> */}
                                            <input
                                                onChange={(e: any) =>
                                                    setCPassword(e.target.value)
                                                }
                                                className="password-ip-p"
                                                type="password"
                                                placeholder="Enter Password"
                                            />
                                        </div>
                                        <div
                                            className="ip-inr-main"
                                            style={{ marginTop: "10px" }}>
                                            {/* <img className="ip-password-img" src="assets/img/lock.png" alt="" /> */}
                                            <input
                                                onChange={(e: any) =>
                                                    setCCPassword(
                                                        e.target.value
                                                    )
                                                }
                                                className="password-ip-p"
                                                type="password"
                                                placeholder="Confirm Password"
                                            />
                                        </div>
                                        <div
                                            className="d-flex"
                                            style={{ marginTop: "20px" }}>
                                            <button
                                                onClick={changePassword}
                                                className="add-biller-btn">
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {isProfile == 3 && (
                            <div className="account-box-main3">
                                <div className="profile-details">
                                    <div className="user-details-ips">
                                        <div className="ip-inr-main">
                                            {/* <img className="ip-password-img" src="assets/img/lock.png" alt="" /> */}
                                            <input
                                                onChange={(e: any) =>
                                                    setCPin(e.target.value)
                                                }
                                                maxLength={4}
                                                className="password-ip-p"
                                                type="password"
                                                placeholder="Enter New Pin"
                                            />
                                        </div>
                                        <div
                                            className="ip-inr-main"
                                            style={{ marginTop: "10px" }}>
                                            {/* <img className="ip-password-img" src="assets/img/lock.png" alt="" /> */}
                                            <input
                                                onChange={(e: any) =>
                                                    setCCPin(e.target.value)
                                                }
                                                maxLength={4}
                                                className="password-ip-p"
                                                type="password"
                                                placeholder="Confirm New Pin"
                                            />
                                        </div>
                                        <div
                                            className="d-flex"
                                            style={{ marginTop: "20px" }}>
                                            <button
                                                onClick={changePin}
                                                className="add-biller-btn">
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Profile;

import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import DashboardNav from "./dashboardNav";
import Confetti from "react-confetti";
import "./dashboard.css";

function Deposits() {
    const navigate = useNavigate();

    return (
        <>
            <div className="main-bg">
                <div>
                    <DashboardNav active={"deposits"}></DashboardNav>
                </div>
                <div className="load-success">
                    <div>
                        <img src="assets/success.png" />
                        <h3>Transaction Success</h3>
                        <p>
                            We received your Load Request and <br /> will
                            reflect in your account shortly
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Deposits;

import React from "react";
import AboutClasses from "./About.module.css";
import Electricity from "../../../images/HomePage_Updated/electricity.png";
import Creditcard from "../../../images/HomePage_Updated/creditcard.png";
import FastTag from "../../../images/HomePage_Updated/fasttag.png";
import hospital from "../../../images/HomePage_Updated/hospital.png";
import cable from "../../../images/HomePage_Updated/cable.png";
import broadband from "../../../images/HomePage_Updated/broadband.png";
import water from "../../../images/HomePage_Updated/water.png";
import education from "../../../images/HomePage_Updated/education.png";
import gas from "../../../images/HomePage_Updated/gas.png";
import Marquee from "react-fast-marquee";
import { Fade } from "react-awesome-reveal";

function About() {
    const Services = [
        { Label: "Electricity", image: Electricity },
        { Label: "Water", image: water },
        { Label: "Gas", image: gas },
        { Label: "Credit Card", image: Creditcard },
        { Label: "Education", image: education },
    ];

    return (
        <div className={AboutClasses["Container"]} id="about">
            {/* <img src={Bg} alt="Bg" /> */}
            <Fade direction="top-right" triggerOnce>
                <h3> Pay bills on Paygen Pro</h3>
            </Fade>

            <div style={{ width: "100%" }} className={AboutClasses["Services"]}>
                <Fade direction="up" cascade duration={500} triggerOnce>
                    {Services.map((item) => (
                        <div
                            className={AboutClasses["Service_Items"]}
                            key={item.Label}>
                            <img src={item.image} alt={item.Label} />
                            <h3>{item.Label}</h3>
                        </div>
                    ))}
                </Fade>
            </div>
        </div>
    );
}

export default About;

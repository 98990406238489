import React, { useState, useEffect, useRef } from "react";
import Moment from "react-moment";
import { useNavigate } from "react-router-dom";
import DashboardNav from "./dashboardNav";
import "./dashboard.css";
import WalletBlcHeader from "./walletBlcHeader";
import LoginServices from "../../services/LoginServices";
import Razor from "./Razor";
import { Modal, Row, Col } from "react-bootstrap";
import { css } from "@emotion/react";

import { PulseLoader } from "react-spinners";
import googleplay from "../../images/googleplay.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactS3Client from "react-aws-s3-typescript";
import { s3Config } from "./s3Config";
import { IoLogoGooglePlaystore } from "react-icons/io5";
const override = css`
    display: block;
    margin: 0 auto;
    border-color: "#010080";
`;

function Kyc() {
    let [loading, setLoading] = useState(false);
    const [walletTransactions, setWalletTransactions] = useState<any>();
    const userId = localStorage.getItem("userid");
    const navigate = useNavigate();
    const [walletBlc, setWalletBlc] = useState<any>(0);
    const [captchImage, setCaptchImage] = useState<any>("");
    const [captchaToken, setCaptchaToken] = useState<any>("");
    const [otpresponse, setotpresponse] = useState<any>();
    const [aadharNumber, setAadharNumber] = useState<any>("");
    const [aadharImageFront, setAadharImageFront] = useState<any>("");
    const [aadharImageBack, setAadharImageBack] = useState<any>("");
    const [panCardImage, setPanCardImage] = useState<any>("");
    const [captchaIpNumber, setCaptchaIpNumber] = useState<any>("");
    const [otpCode, setverifyOtp] = useState<any>("");
    const [showOtp, setShowOtp] = useState<any>(false);

    const inputFileA1: any = {};
    const inputFileA2: any = {};
    const inputFileP: any = {};

    useEffect(() => {
        if (userId) {
            if (localStorage.getItem("isKycDone") == "1") {
                alert("KYC verification already done");
                navigate("/dashboard");
            }
            if (localStorage.getItem("isKycDone") == "2") {
                alert("KYC verification done waiting for Approval");
                navigate("/dashboard");
            }
            let config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            };
            LoginServices.getWalletBalanceByUserID(parseInt(userId), config)
                .then((response: any) => {
                    console.log(response);
                    if (response.data.Status == 1) {
                        setWalletBlc(response?.data?.walletBalance);
                    } else {
                    }

                    console.log(response.data);
                })
                .catch((e: Error) => {
                    console.log(e);
                });

            // resendOtp()
        } else {
            alert("Login to see your dashboard");
            navigate("/");
        }
    }, [""]);

    const sendOtp = () => {
        // const payload = {
        //     aadharNumber: aadharNumber,
        //     SessionId: captchaToken,
        //     captcha: captchaIpNumber,
        //     aadharFront: aadharImageFront,
        //     aadharBack: aadharImageBack,
        //     PANImage: panCardImage,
        // };
        const payload = {
            aadharNumber: aadharNumber,
        };
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        setLoading(true);
        LoginServices.sendOtpKyc(payload, config)
            .then((response: any) => {
                console.log(response);
                if (response.data.Status == 1) {
                    setotpresponse(response.data);
                    setLoading(false);
                    setShowOtp(true);
                } else {
                    setLoading(false);
                    alert(response.data.Message);
                }
                console.log(response.data);
            })
            .catch((e: Error) => {
                setLoading(false);
                console.log(e);
            });
    };
    const resendOtp = () => {
        setLoading(true);
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        // LoginServices.getCaptcha(config)
        //     .then((response: any) => {
        //         console.log(response);
        //         if (response.data.Status == 1) {
        //             setLoading(false)
        //             setCaptchImage(response?.data?.ImageURL)
        //             setCaptchaToken(response?.data?.SessionId)

        //         }
        //         else {
        //             alert(response?.data?.message)
        //             setLoading(false)
        //         }

        //         console.log(response.data);
        //     })
        //     .catch((e: Error) => {
        //         console.log(e);
        //         setLoading(false)

        //     });
    };
    const verifyOtp = () => {
        const payload = {
            OTP: otpCode,
            RequestID: otpresponse.RequestID,
            aadharNumber: aadharNumber,
        };
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        setLoading(true);
        LoginServices.sendVerifyOtp(payload, config)
            .then((response: any) => {
                console.log(response);
                const userid = localStorage.getItem("userid");
                if (userid) {
                    LoginServices.getUserDetailsByID(parseInt(userid), config)
                        .then((response: any) => {
                            console.log(response);
                            navigate("/dashboard");
                            if (response.data.Status == 1) {
                                localStorage.setItem(
                                    "isKycDone",
                                    response?.data?.UserDetails?.isKYCverified
                                );
                            } else {
                            }
                            console.log(response.data);
                        })
                        .catch((e: Error) => {
                            console.log(e);
                        });
                }
                if (response.data.Status == 1) {
                    setLoading(false);
                    alert(response.data.Message);
                    navigate("/dashboard");
                } else {
                    setLoading(false);
                    alert(response.data.Message);
                }
                console.log(response.data);
            })
            .catch((e: Error) => {
                setLoading(false);
                console.log(e);
            });
    };

    let handleAadharFront = async (e: any, rCode: string) => {
        if (!userId) {
            return;
        }
        const file = e.target.files[0];
        const { name } = e.target;

        e.persist();
        const fileName = (userId + "_" + new Date().getTime() / 1000)
            .split(".")
            .join("");
        const myNewFile = new File([file], fileName, { type: file.type });
        const s3 = new ReactS3Client(s3Config);
        await s3
            .uploadFile(myNewFile, fileName)
            .then((data) => {
                console.log(data);

                let url = data.location;
                if (rCode == "r1") {
                    setAadharImageFront(url);
                }
                if (rCode == "r2") {
                    setAadharImageBack(url);
                }
                if (rCode == "r3") {
                    setPanCardImage(url);
                }
                // setChallanImage(url);
                // let imgName = data.location.split("/");

                // setDetails({
                //     ...details,
                //     ...{ [name]: imgName[imgName.length - 1] }
                // })
            })
            .catch((err) => console.error(err));
    };

    return (
        <>
            <div className="main-bg" style={{ overflowX: "hidden" }}>
                <div>
                    <DashboardNav active={"kyc"}></DashboardNav>
                </div>
                <div>
                    <p className="kyc-head">KYC Verification </p>

                    <div>
                        <div
                            className="row body "
                            style={{
                                alignItems: "center",
                                justifyContent: "center",
                                margin: "none",
                                overflow: "hidden",
                                marginTop: "40px",
                            }}>
                            <div className="kyc-main-div kyc-header">
                                {/*  <h3>
                                    Please complete the KYC process in the App.
                                    Download the app from here
                                </h3>
                                <a
                                    href="https://play.google.com/store/apps/details?id=com.app.Paygen"
                                    target={"_blank"}>
                                    <img alt="googleplay" src={googleplay} />
                                    Google Play
                                </a> */}
                                {!showOtp && (
                                    <div>
                                        <div className="ip-inr-main">
                                            <label
                                                style={{ width: "260px" }}
                                                className="label">
                                                Aadhaar Number
                                            </label>
                                            <input
                                                onChange={(e) => {
                                                    setAadharNumber(
                                                        e.target.value
                                                    );
                                                }}
                                                type="text"
                                                name="amount"
                                                id="amount"
                                                autoComplete="off"
                                                className="add-blc-ip"
                                                placeholder="Enter your aadhaar number"
                                            />
                                        </div>

                                        <div className="captcha-img d-flex p-3">
                                            <PulseLoader
                                                color={"#010080"}
                                                loading={loading}
                                                css={override}
                                                size={20}
                                            />
                                            <img
                                                src={captchImage}
                                                alt=""
                                                style={{ margin: "auto" }}
                                            />
                                        </div>

                                        <div className="ip-inr-main d-flex">
                                            <button
                                                style={{ margin: "15px auto" }}
                                                onClick={sendOtp}
                                                className="btn add-btn">
                                                Get OTP
                                            </button>
                                        </div>
                                    </div>
                                )}
                                {showOtp && (
                                    <div>
                                        <div className="ip-inr-main">
                                            <label
                                                style={{
                                                    width: "200px",
                                                    textAlign: "center",
                                                }}
                                                className="label">
                                                OTP
                                            </label>
                                            <input
                                                onChange={(e) => {
                                                    setverifyOtp(
                                                        e.target.value
                                                    );
                                                }}
                                                type="number"
                                                name="amount"
                                                id="amount"
                                                autoComplete="off"
                                                className="add-blc-ip"
                                                placeholder="Enter OTP "
                                            />
                                        </div>
                                        <div className="ip-inr-main d-flex p-3">
                                            <button
                                                onClick={verifyOtp}
                                                className="btn add-btn ">
                                                Verify OTP
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Kyc;

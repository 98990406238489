import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import DashboardNav from "./dashboardNav";
import "./dashboard.css";
import WalletBlcHeader from "./walletBlcHeader";
import LoginServices from "../../services/LoginServices";
import { css } from "@emotion/react";
import { PulseLoader } from "react-spinners";
import Billpayment from "./billpaymentNav";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const override = css`
    display: block;
    margin: 0 auto;
    border-color: "#010080";
`;

function KycPending() {
    let [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [refreshP, setRefreshP] = useState<any>("");

    const [walletBlc, setWalletBlc] = useState<any>(0);

    const userId = localStorage.getItem("userid");

    const Transcations = [
        {
            TransactionID: 123456,
            TransactionDate: "24 feb 2022",
            AgentID: 123456,
            BillerName: "Kolias",
            Amount: "400",
            Status: "Completed",
            print: "img",
        },
        {
            TransactionID: 123456,
            TransactionDate: "24 feb 2022",
            AgentID: 123456,
            BillerName: "Mahesh ",
            Amount: " 400",
            Status: "pending",
            print: "img",
        },
        {
            TransactionID: 123456,
            TransactionDate: "24 feb 2022",
            AgentID: 123456,
            BillerName: "Solani",
            Amount: " 400",
            Status: "Completed",
            print: "img",
        },
        {
            TransactionID: 123456,
            TransactionDate: "24 feb 2022",
            AgentID: 123456,
            BillerName: "Samiksha",
            Amount: " 400",
            Status: "Completed",
            print: "img",
        },
        {
            TransactionID: 123456,
            TransactionDate: "24 feb 2022",
            AgentID: 123456,
            BillerName: "Raj",
            Amount: " 400",
            Status: "Completed",
            print: "img",
        },
        {
            TransactionID: 123456,
            TransactionDate: "24 feb 2022",
            AgentID: 123456,
            BillerName: "Kolias",
            Amount: " 400",
            Status: "pending",
            print: "img",
        },
        {
            TransactionID: 123456,
            TransactionDate: "24 feb 2022",
            AgentID: 123456,
            BillerName: "Kolias",
            Amount: " 400",
            Status: "pending",
            print: "img",
        },
        {
            TransactionID: 123456,
            TransactionDate: "24 feb 2022",
            AgentID: 123456,
            BillerName: "Kolias",
            Amount: " 400",
            Status: "Completed",
            print: "img",
        },
        {
            TransactionID: 123456,
            TransactionDate: "24 feb 2022",
            AgentID: 123456,
            BillerName: "Raj",
            Amount: " 400",
            Status: "pending",
            print: "img",
        },
        {
            TransactionID: 123456,
            TransactionDate: "24 feb 2022",
            AgentID: 123456,
            BillerName: "mahesh",
            Amount: " 400",
            Status: "Completed",
            print: "img",
        },
    ];

    useEffect(() => {
        if (userId) {
            let config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            };
            LoginServices.getWalletBalanceByUserID(parseInt(userId), config)
                .then((response: any) => {
                    console.log(response);
                    if (response.data.Status == 1) {
                        setWalletBlc(response?.data?.walletBalance);
                    } else {
                        alert(response?.data?.Message);
                    }

                    console.log(response.data);
                })
                .catch((e: Error) => {
                    console.log(e);
                });
        } else {
            alert("Login to see your dashboard");
            navigate("/");
        }
    }, [refreshP == "yes"]);
    // showSection == 'biller-list'

    return (
        <>
            <div className="main-bg">
                <div>
                    <DashboardNav active={"bill-payments"}></DashboardNav>
                </div>
                <div className="paymentWrapper">
                    <div className="payment-header">
                        <h2>Bill Payments</h2>
                        <img
                            className="payment-header-icon"
                            src="assets/billpayment/billpayment_header.png"
                            alt=""
                        />
                    </div>
                </div>

                <div className="TranscationWrapper">
                    <Billpayment active="transaction" />
                    <div className="transcations">
                        <div className="filter">
                            <div className="filter-control">
                                <label htmlFor="To Date">To Date</label>
                                <input
                                    className="input"
                                    type={"date"}
                                    name="from Date"
                                />
                            </div>
                            <div className="filter-control">
                                <label htmlFor="FromDate">From Date</label>
                                <input
                                    className="input"
                                    type={"date"}
                                    name="fromDate"
                                />
                            </div>
                            <div className="filter-control">
                                <label htmlFor="MobileNumber">
                                    Mobile Number
                                </label>
                                <input
                                    className="input"
                                    type={"number"}
                                    name="MobileNumber"
                                />
                            </div>
                            <div className="filter-control">
                                <label htmlFor="TransactionID">
                                    Transaction ID
                                </label>
                                <input
                                    className="input"
                                    type={"number"}
                                    name="TransactionID"
                                />
                            </div>
                            <button>Submit</button>
                        </div>
                        <div className="transcationTable">
                            <table>
                                <tr>
                                    <th>Transaction ID </th>
                                    <th>Transaction Date</th>
                                    <th>Agent ID</th>
                                    <th>Biller Name</th>
                                    <th>Amount</th>
                                    <th>Status</th>
                                    <th>Print</th>
                                </tr>
                                {Transcations.map((element) => {
                                    return (
                                        <tr>
                                            <td>{element.TransactionID}</td>
                                            <td>{element.TransactionDate}</td>
                                            <td>{element.AgentID}</td>
                                            <td>{element.BillerName}</td>
                                            <td>&#8377; {element.Amount}</td>
                                            <td>{element.Status}</td>
                                            <td>
                                                <button>
                                                    <img
                                                        src="assets/img/print.svg"
                                                        alt=""
                                                    />
                                                </button>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </table>
                        </div>
                    </div>
                </div>
                <div>
                    <ToastContainer
                        position="top-center"
                        autoClose={5000}
                        hideProgressBar
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </div>
            </div>
        </>
    );
}

export default KycPending;
